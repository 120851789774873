import * as React from "react"
import { ChevronDown, ChevronRight, ChevronLeft } from "lucide-react"
import { Button } from "@/src/components/ui/button"
import {DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger} from "@/src/components/ui/dropdown-menu"
import { Input } from "@/src/components/ui/input"
import { DataTableFacetedFilter } from "./filter"
import './TableCss.css'
const countries = [
    {
      value: "England and Wales",
      label: "England And Wales",
    },
    {
      value: "Scotland",
      label: "Scotland",
    },
  
  ]
  
const judgments = [
    {
      value: "positive",
      label: "Positive",
    },
    {
      value: "partially positive",
      label: "Partially positive",
    },
    {
      value: "negative",
      label: "Negative",
    },
    {
      value: "undetermined",
      label: "Undetermined",
    },
  ]


const TableToolbar = (props) => {
    const [chatBtnText, setChatBtnText] = React.useState("Show AI Chat")
    const [searchText, setSearchText] = React.useState('')
    const [searchState, setSearchState] = React.useState(false)
    const table = props.table
    const handleChatBtn = ()=>{
        setChatBtnText(chatBtnText=='Show AI Chat' ? 'Hide AI Chat' : 'Show AI Chat')
        props.handleShowChat(chatBtnText)


    }
    
    const handleSearchSubmit = async (e)=>{
      e.preventDefault()
      setSearchState(true)
      await props.handleTableSearch(searchText)
      setSearchState(false)
      
    }
    return(
        <div className="w-full h-12 sticky top-0 left-0 z-20 bg-white flex items-center py-1 ">
        <form className="flex w-full" onSubmit={(e)=>handleSearchSubmit(e)}>
        <Input
            type="text"
            name='search'
            placeholder="Search Cases..."
            value={searchText}
            onChange={(e)=>setSearchText(e.target.value)}
            disabled ={searchState}
            
            className="max-w-sm border border-blue-200"
        />

        <Button variant='outline' className='ml-2' disabled={searchState} type='submit'>
            Search
        <ChevronRight className="ml-2 h-4 w-4" />
        </Button>
        </form>
        <div className="ml-auto"></div>
        {/*These are dropdown select filters */}

        {table.getColumn("judgment") && (
        <DataTableFacetedFilter
            onCheckedChange={(e)=>console.log(e)}
            className='ml-2'
            column={table.getColumn("judgment")}
            title="Judgment"
            options={judgments}
            table={table}
        />
        )}

        {table.getColumn("country") && (
        <DataTableFacetedFilter
            className="ml-2"
            column={table.getColumn("country")}
            title="Country"
            options={countries}
        />
        )}

        <DropdownMenu>
            <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-2">
                Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
            {table
                .getAllColumns()
                .filter(column => column.getCanHide())
                .map(column => {
                return (
                    <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={value => column.toggleVisibility(!!value)}
                    >
                    {column.id}
                    </DropdownMenuCheckboxItem>
                )
                })}
            </DropdownMenuContent>
        </DropdownMenu>

        <Button variant="outline" className="Show-chat-button ml-2 mr-2 " onClick={()=>handleChatBtn()}>
                {chatBtnText} {chatBtnText=='Show AI Chat' ?<ChevronRight className="ml-2 h-4 w-4" />:<ChevronLeft className="ml-2 h-4 w-4" /> }
        </Button>

        </div>
        )};

export {TableToolbar}