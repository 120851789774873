import { useState, useEffect } from "react";
import { GetRequest } from "../../functions/requestTemplates";
import Sidebar from "./components/Q&A-Sidebar";
import CollectionView from "./components/Q&A-CollectionView";
import CreateCollection from "./components/Q&A-CreateCollection";
import { useAppState } from "../../hooks/AppState";
import Loader from "../../components/other/Loader";
const QandA = () => {
    const [collection, setCollection] = useState([])
    const [collectionList, setCollectionList] = useState(null)
    const [selectedView, setSelectedView] = useState('View')
    const appState = useAppState()

    const handleGetCollections = async()=>{
        const data = await GetRequest('question_collection/?chatbot_id=', appState.chatbot.value)
        if (data!==null){
            setCollectionList(data.data)
        }
    }

    const handleRefreshCollection = async()=>{
        const collectionData = await GetRequest('question_answer/?collection_id=', collection.id)
        setCollection({'id':collection.id, 'data':collectionData})
    }

    useEffect(()=>{
        if(appState.chatbot.data !== undefined){

            handleGetCollections()
        }
        
    },[appState.chatbot])


    return(
        <div className="PageContainer flex-row h-full">
            
            <Sidebar collectionList={collectionList} setCollection={setCollection} setSelectedView={setSelectedView}/>

            <div className="w-full" hidden={selectedView!=='View'}>
                <CollectionView chatbot_id={appState.chatbot.value} collection={collection} refresh={handleRefreshCollection} collectionList={collectionList}/>
            </div>
            


            <div className="w-full" hidden={selectedView!=='Create'}>
                <CreateCollection chatbot_id={appState.chatbot.value} handleGetCollections={handleGetCollections} />
            </div>
            

            
        </div>
    )
}

export default QandA;