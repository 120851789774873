import './chat.css'
import { useState, useEffect } from 'react'
import ChatbotTestInterface from './pages/ChatbotTestInterface'
import ChatInterface from './pages/ChatWindow'
import DatabasePage from './pages/DataBasePage'
import { useAppState } from '../../hooks/AppState'
const TestChatPage = (props)=>{
    const[page, setPage] = useState('chat')
    const appState = useAppState()
    

    const handlePage=(page)=>{
        setPage(page)
    }


    useEffect(()=>{
    },[appState.chatbot])

    
    return(
        <div className="PageContainer h-full  w-full overflow-x-hidden ">

            <DatabasePage show={page=='database'}/>


        </div>
    )
}

export default TestChatPage;