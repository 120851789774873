import "../../CSS/Login.css"
import { useState } from "react"
import { PostRequest } from "../../functions/requestTemplates"
import { useAuth } from "../../hooks/Authenticate"
import { Link } from "react-router-dom"
const ChangePasswordPage = () =>{
    const [passwordForm, setPasswordForm]= useState({current_password:'', new_password:'', confirm_new_password:''})
    const [status, setStatus] = useState('awaiting')
    const [focused, setFocused] = useState(null)

    const updatePassword = async (data)=>{
        const request = await PostRequest('change_password/', data)
        if(request.action === 'success'){
            setStatus('success')
        }else{
            console.log(request.status)
            setStatus('failed')
        }
    }

    const handleRegisterInput = (e)=>{
        const name = e.target.name
        const value = e.target.value
        if(e.target.name === 'new_password'){
            const pass_input = document.getElementById('new_password')
            pass_input.classList.remove('Form-input-invalid')
        }

        if(e.target.name === 'confirm_new_password'){
            const pass_confirm = document.getElementById('confirm_new_password')
            pass_confirm.classList.remove('Form-input-invalid')
        }

        setPasswordForm((prev)=>({...prev, 
            [name]: value
        }))

    }

    const verify_password = (password)=>{
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if(pattern.test(password)){
            console.log('password is valid')
            return true
        }else{
            console.log('password is invalid')
            return false
        }
    }

    async function submitRegister (e){
        e.preventDefault()
        const pass_input = document.getElementById('new_password')
        const pass_confirm = document.getElementById('confirm_new_password')
        pass_input.classList.remove('Form-input-invalid')
        pass_confirm.classList.remove('Form-input-invalid')
        console.log(passwordForm.new_password)
        
        if(verify_password(passwordForm.new_password)){
            const password_check = true
        }else{
            const password_check = false
            pass_input.classList.add('Form-input-invalid')
            pass_confirm.classList.add('Form-input-invalid')
            return
        }
        if(passwordForm.new_password === passwordForm.confirm_new_password){
            const password_match = true}
        else{
            pass_confirm.classList.add('Form-input-invalid')
            alert('Passwords do not match')
            return
        }
        updatePassword(passwordForm)
        
        
}




    return(

        <div className="PageContainer">
            {status === 'awaiting' ?

            <div className="Form-container self-center">
                <form autoComplete='off' className="flex flex-col " onSubmit={(e)=>{submitRegister(e)}}>
                    <label>Change your password</label>

                    <input className="Form-input" onFocus={()=>setFocused('password')}  id='old_password' type="password" name='current_password' autoCompletel='new-password' placeholder="Current password" onChange={e => handleRegisterInput(e)}></input>
                    
                    <input className="Form-input" onFocus={()=>setFocused('password')}  id='new_password' type="password" name='new_password' autoCompletel='new-password' placeholder="New password" onChange={e => handleRegisterInput(e)}></input>
                    
                    <input className="Form-input" onFocus={()=>setFocused('password2')}  id='confirm_new_password' type="password" name='confirm_new_password' autoComplete='new-password'  placeholder="Confirm new password" onChange={e => handleRegisterInput(e)}></input>
                    
                    <button className="Form-button" type='submit'>Update Password</button>
                    
                    <label className="text-white text-xs">Your password should be a minimum of 8 characters long and contain at least; one uppercase letter, one lowercase letter, one number and one symbol (!,?,#)</label>   
                </form>
            </div>

            : status === 'success' ?
            
            <div className="Form-container self-center">
                <label>
                    Password updated succesfully! Please use your new password next time you log in
                </label>
                <Link to='/panel/profile'>
                    <button className="Form-button" >Return To Profile</button>
                </Link>
                
                
                
            </div> 


            :             
            
            <div className="Form-container self-center">
                <div className=" bg-white rounded-md">
            <label>
                Password update failed, please check that you entered you password in correctly and try again
            </label>

                </div>


            <button className="Form-button" onClick={()=>setStatus('awaiting')}>Try Again</button>
            
            
            </div> 
            }
        </div>
    )
}

export default ChangePasswordPage;