import { useState, createRef, useEffect } from "react"
import GetChatbots from "../../../functions/requestChatbots"
import { useNavigate } from "react-router-dom"
import {DeleteProject, GetProjectData, UpdateProjectData} from "../projectSettingsApi"
import ChatbotManage from "../components/chatbotsWindow"
import ProjectDeleteOverlay from "../components/overlayDeleteProject"
import { useAppState } from "../../../hooks/AppState"
const ProjectSettings = (props)=>{
    const [projectName, setProjectName] = useState()
    const [projectType, setProjectType] = useState('Company Website')
    const [productionChatbot, setProductionChatbot] = useState()
    const [projectWebsite, setProjectWebsite] = useState()
    const [edit, setEdit] = useState(false)
    const [chatbots, setChatbots] = useState()
    const [deleteOverlay, setDeleteOverlay]=useState()
    const appState = useAppState()
    const uploadFiles = createRef()
    const navigate = useNavigate()
    async function showChatbots(){
        if (appState.project.value !== 'None' && appState.project.value !== 'none'){
        
        let chatbot_list = []
        const chatbots = await GetChatbots(appState.project.value)

        for(const i in chatbots){
            const array = chatbots[i]
            const element = <option key={i} name={array[0]} value={array[1]}>{array[0]}</option> 
            chatbot_list.push(element)
                 
        }
        setChatbots(chatbot_list)}
    }

    async function getProjectData(){
        if (appState.project.value !== 'None' && appState.project.value !== 'none' && appState.project.value !== undefined){
        const data = await GetProjectData(appState.project.value)
        setProjectName(data['project_name'])
        setProjectType(data['project_type'])
        setProductionChatbot(data['production_chatbot_id'])
        setProjectWebsite(data['project_website'])
        

    }else{
        navigate('/panel/create_project')
    }
    }


    const toggleEdit = ()=>{
        const save_btn = document.getElementById('save_button')
        const cancel_btn = document.getElementById('cancel_button')
        const name = document.getElementById('name_input')
        const type = document.getElementById('type_select')
        const prod = document.getElementById('production_chatbot_select')
        const website = document.getElementById('website_input')
        const formElements = [name, type, prod, website]

        if(edit){
            for(const i in formElements){
                formElements[i].disabled = true
            }
            cancel_btn.classList.add('hidden')
            save_btn.classList.add('hidden')
            setEdit(false)
            

        }else{
            for(const i in formElements){
                formElements[i].disabled = false
            }
            cancel_btn.classList.remove('hidden')
            save_btn.classList.remove('hidden')
            setEdit(true)

        }

    }


    async function formSubmit(e){
        e.preventDefault()
        const data = {
            'project_id' : appState.project.value,
            'project_name': projectName,
            'project_type': projectType,
            'production_chatbot_id' : productionChatbot,
            'project_website' : projectWebsite
        }
        await UpdateProjectData(data)
        appState.processSessionData(appState.project, appState.chatbot)
        toggleEdit()

    }

    useEffect(()=>{
       showChatbots() 
       getProjectData()
       setEdit(false)
    },[appState.project])

    


    return(
        <div className="PageContainer">
            <div className="w-2/3 flex flex-col self-center bg-slate-300 mt-10">
                <h1 className="mt-6 mb-6"> Project Settings :  {appState.project.name}</h1>
            <div className="bg-slate-200 max-w-[97%] w-full self-center">
            <form className="GridContainer" onSubmit={(e) =>formSubmit(e)}>

                <label className="grid-item1" id="name" >Project Name</label>
                <input className="grid-item2" id="name_input" type="string" placeholder="My Chatbot" value={projectName} disabled={true} onChange={(e)=>setProjectName(e.target.value)}></input>

                <label className="grid-item1" id="type">Project Type</label>
                <select className="grid-item2" id="type_select" placeholder="Choose your website type.." value={projectType} disabled={true} onChange={(e)=>setProjectType(e.target.value)}>
                    <option value={'Company Website'}>Company Website</option>
                    <option value={'Store'}>Store</option>
                </select>

                <label className="grid-item1" id="production_chatbot">Production Chatbot</label>
                <select className="grid-item2" id="production_chatbot_select" placeholder="Choose Production Chatbot" value={productionChatbot} disabled={true} onChange={(e)=>setProductionChatbot(e.target.value)}>
                    <option value={'None'} disabled>Choose Production Chatbot</option>
                    {chatbots}

                </select>

                <label className="grid-item1" id="website" >Project Website</label>
                <input className="grid-item2" id="website_input" type="string" placeholder="MyChatbot.com" value={projectWebsite} disabled={true} onChange={(e)=>setProjectWebsite(e.target.value)}></input>



      


            </form>            

                <button className="Action-button m-2 bg-red-500 " hidden={!edit} id='cancel_button' onClick={toggleEdit}>Cancel</button>
                <button className="Action-button m-2 bg-green-500 " hidden={!edit} id='save_button' type='submit' onClick={(e)=>formSubmit(e)}>Save</button>
                <button className="Action-button m-2" hidden={edit} onClick={()=>toggleEdit()}>Edit</button>
                <button className="Action-button m-2" hidden={edit} onClick={()=>setDeleteOverlay(true)}>Delete project</button>
                </div> 
                <div>
                    <ChatbotManage edit={!edit}/>
                    <ProjectDeleteOverlay hide={!deleteOverlay} setShow={setDeleteOverlay}/>
                </div>
            </div>

        </div>
    )
}
export default ProjectSettings;