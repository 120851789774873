import "../../CSS/Login.css"
import { useState } from "react"
import { useAuth } from "../../hooks/Authenticate"
import { Link } from "react-router-dom"
const RegisterPage = () =>{
    const [registerForm, setRegisterForm]= useState({email:'', password:'', confirmPassword:'', first_name:'', last_name:''})
    const [focused, setFocused] = useState(null)

    const handleRegisterInput = (e)=>{
        const name = e.target.name
        const value = e.target.value
        setRegisterForm((prev)=>({...prev, 
            [name]: value
        }))
    }

    const verify_password = (password)=>{
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if(pattern.test(password)){
            console.log('password is valid')
            return true
        }else{
            console.log('password is invalid')
            return false
        }
    }

    const verify_email = (email)=>{
        if(email !== ''  && email.indexOf('@')>-1 && email.indexOf('.')>-1){
            console.log('email is valid')
            return true
        }else{
            console.log('mail is invalid')
            return false
        }
    }

    const verify_details = (first_name, last_name)=>{
        let valid = false
        if(first_name !== '' && first_name.length >1){
            console.log('firstname is valid')
            valid =  true
        }else{
            console.log('first name is invalid')
            valid =  false
        } 

        if(last_name !== '' && last_name.length >1){
            console.log('lastname is valid')
            valid = true
        }else{
            console.log('last name is invalid')
            valid = false
            }
    }



    async function submitRegister (e){
        
        e.preventDefault()
        if(registerForm.email !== ''  && registerForm.email.indexOf('@')>-1 && registerForm.email.indexOf('.')>-1){
            const email_check = true
            console.log('email is valid')
        }else{
            console.log('mail is invalid')
            const email_check = false
        }
        if(verify_password(registerForm.password, registerForm.confirmPassword)){
            const password_check = true

        }else{

            const password_check = false
        
        }
        
}




    return(
        <div className="absolute top-[0] left-[0] w-full">
        <div className="flex w-full justify-center">
            <div className="Form-container">
                <div id='logo' className='Login-logo-container'>
                    <img className='Login-logo' src='/logos/logo-ai-zuzi-ikona-1-100px.png' alt='logo'/>
                </div>
                <form className="flex flex-col" onSubmit={(e)=>{submitRegister(e)}}>
                    <input className="Form-input" onFocus={()=>setFocused('name')} type="text" name='first_name' placeholder="First Name" onChange={e => handleRegisterInput(e)}></input>
                    <input className="Form-input" onFocus={()=>setFocused('name2')} type="text" name='last_name' placeholder="Last Name" onChange={e => handleRegisterInput(e)}></input>
                    <input className="Form-input" onFocus={()=>setFocused('e-mail')} id='e-mail' type="text" name='email' placeholder="E-mail" onChange={e =>handleRegisterInput(e)}></input>

                    <label className="text-white text-xs" hidden={focused!=='password'}>Your password should be a minimum of 8 characters long and contain atleast; 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol(!,?,#)</label>
                    
                    <input className="Form-input" onFocus={()=>setFocused('password')}  id='password' type="password" name='password' placeholder="Password" onChange={e => handleRegisterInput(e)}></input>
                    
                    <input className="Form-input" onFocus={()=>setFocused('password2')}  id='confirm_passord' type="password" name='confirm_password' placeholder="Confirm Password" onChange={e => handleRegisterInput(e)}></input>
                    
                    <button className="Form-button" type='submit'>Create Account</button>
                    
                    
                </form>
                <Link to='/panel/login'>
                    <button className="Form-button">Return to login</button>
                </Link>
            </div>
        </div>
    
    </div>)
}

export default RegisterPage;