const Table = ({ data }) => {
    return(
        <div>
            <table className=" ml-3 w-[98%] text-xl bg-white">
                <thead>
                    <tr>
                        <th className="text-center w-1/5">Question</th>
                        <th className="text-center w-1/5">Answer</th>
                        <th className="text-center w-1/5">Actions</th>
                        <th className="text-center w-1/5">Operations</th>
                    </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </div>
    )
}

export default Table;