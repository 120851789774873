import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../../components/other/Urls";


async function queryChunks(chunk_search){
    const api_url = Api_url()
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chunk_query',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':chunk_search

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}
    
            


export {queryChunks};