import "../../CSS/Sidebar.css"
import {Link} from 'react-router-dom'
import { useAuth } from '../../hooks/Authenticate'
import ProjectSelect from './components/ProjectSelect'
import ChatbotSelect from './components/ChatbotSelect'

const SidebarUser = (props) =>{
    const auth = useAuth()


    const logout = () =>{
        auth.logoutAction()
    }

    
    if(auth.user){

    return(
        <div className='Sidebar'>

            
            <div id='logo' className='Sidebar-logo-container'>
                <img className='Sidebar-logo' src='/static/logo-ai-zuzi-ikona-1-100px.png' alt='logo'/>
            </div>

            <Link to='/panel/profile'  onClick={()=>props.handleSelectedRoute('my-profile')}>
                <button className='Sidebar-button' id='my-profile'>My Profile</button>
            </Link>

            <h2 className='underline underline-offset-4 text-lg'>Selected Assistant</h2>
            <ChatbotSelect/>

            
            <h2 className='underline underline-offset-4 text-lg'> Selected Project</h2>

            <ProjectSelect/>




            <Link to='/panel/login'>
                <button className='Logout-button' onClick={()=>logout()}>Logout</button>
            </Link>
            
        </div>
    )}
}

export default SidebarUser;