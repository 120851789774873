import React from 'react'
import { Navigate, Outlet , useNavigate} from 'react-router-dom';
import { useAuth } from '../../hooks/Authenticate';
const AdminRoute = (auth) => {

    const user = useAuth()

    return user.userType === 'Admin'? <Outlet /> : <Navigate replace={true} to="/panel/cms/" />;
}

export default AdminRoute;