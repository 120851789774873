import ChatInput from "./ChatInput"
import {ChatMessageUser, ChatMessageBot} from "./ChatMessage";
import { useAppState } from "@/src/hooks/AppState";
import { useState, useEffect } from "react";
import { GetRequest, PostRequest } from "@/src/functions/requestTemplates";
import Typing from "../userTyping";
const ChatWindow = (props) => {
    const [messages, setMessages] = useState([])
    const [conversation, setConversation] = useState(null)
    const [showTypingBubble, setShowTypingBubble] = useState(false)
    const appState = useAppState()


    const getConversation = async ()=>{
        setShowTypingBubble(true)
        setMessages([])
        const response = await GetRequest('chatbot_conversation/?chatbot_id=', appState.chatbot.value)
        setConversation(response.conversation_id)
        addMessageBot(response.message)
        setShowTypingBubble(false)
    }


    const getResponse = async (message, mode, length) => {
        console.log(mode, length)
        const body = {
            user_message: message,
            response_mode: mode,
            response_length: length,
            chatbot_id: appState.chatbot.value,
            conversation_id: conversation,
            parent_ids: props.selectedIds,
            partition:'UK_GOV_LAW_EMPLOYMENT'
        }
        const response = await PostRequest('chatbot_conversation/', body)
        addMessageBot(response.response)

    }

    const addMessageUser = (message, mode, length)=>{
        console.log(mode)
        const msg = (<ChatMessageUser message={message}/>)
        setMessages((prev)=>[msg, ...prev])  
        getResponse(message, mode, length)
        setShowTypingBubble(true)

    }

    const addMessageBot = (messages)=>{
        for (const i in messages){
            const msg = (<ChatMessageBot message={messages[i]['message']} link={messages[i]['link']}/>)
            setMessages((prev)=>[msg, ...prev])
            setShowTypingBubble(false)
        }


    }

    useEffect(()=>{
        if (appState.chatbot.value){
            getConversation()
        }
    },[appState.chatbot.value])

    return(
        <div className="flex flex-col h-full w-full p-2">
            <div className="h-full border mb-1 px-2 overflow-y-scroll scroll-smooth text-sm [scrollbar-width:thin]">
                <div id='message-window' className="flex flex-col-reverse min-h-full">

                    <div className='m-2' hidden={!showTypingBubble}>
                        <Typing/>  
                    </div>
                
                    {messages}
                
                </div>
            </div>     
            <ChatInput addMessageUser={addMessageUser}/>
        </div>
    )
}

export default ChatWindow;