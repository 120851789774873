import { useState, useEffect } from "react";
import Chunk from "./chunksObject";
import Loader from "../../../components/other/Loader";
import {queryChunks } from "../api/chunksApi";
import { useAppState } from "../../../hooks/AppState";
const ViewChunks =(props)=>{
    const [maxResults, setMaxResults] = useState(50)
    const [searchInput, setSearchInput] = useState(' ')
    const [chunks, setChunks]=useState()
    const [filters, setFilters] = useState()
    const [loading, setLoading] = useState(false)

    const appState = useAppState()

    const [activeChatbot, setActiveChatbot] = useState()
    

    async function similarQuery(query){
        const chatbot_id = appState.chatbot.value
        const chunkData = {
            'chatbot_id':chatbot_id,
            'max_results':10,
            'search_query':query,
            'filters':filters,
            
        }
        const result = await queryChunks(chunkData)
        let chunks_list = []
        for(const i in result){
            const chunk = <Chunk data={result[i]} searchChunks={(query)=>handleSimilarQuery(query)}/>
            chunks_list.push(chunk)
        }
        setChunks(chunks_list)

    }

    const handleSimilarQuery=(query)=>{
        setChunks(<div></div>)
        similarQuery(query)

    }    
    async function chunksQuery(){
        setChunks('')
        setLoading(true)
        const chunkData = {
            'chatbot_id':activeChatbot,
            'max_results':maxResults,
            'search_query':searchInput,
            'filters':filters,
            
        }
        const result = await queryChunks(chunkData)
        let chunks_list = []
        for(const i in result){
            const chunk = <Chunk data={result[i]} searchChunks={(query)=>handleSimilarQuery(query)}/>
            chunks_list.push(chunk)
        }
        setChunks(chunks_list)
        setLoading(false)
    }

    useEffect(()=>{
        setActiveChatbot(appState.chatbot.value)
    },[appState.chatbot])

    
    return(
        <div hidden={props.hide}>
            <div className="flex flex-col">

            

                <div className="flex flex-row bg-slate-300 p-3">
                <div className="mr-3 ml-3">
                        <select className="p-1 pl-2 rounded-md bg-sky-400" defaultValue={0} value={activeChatbot} onChange={(e)=>setActiveChatbot(e.target.value)}>
                            <option disabled value={0}>Select chatbot</option>
                            {appState.chatbotDropdown}
                        </select>
                    </div>    
                <div className="mr-3 ml-3">
                        <input min={1} max={200} placeholder="Enter query..." type="string" onChange={(e)=>setSearchInput(e.target.value)}></input>
                    </div> 

                    <div className="mr-3 ml-3">
                        <label className="m-2">Max Results</label>
                        <input min={1} max={200} defaultValue={50} type="number" onChange={(e)=>setMaxResults(e.target.value)}></input>
                    </div> 

                    <div className="mr-3 ml-3">
                        <button className="Action-button mt-0 mb-0" onClick={()=>chunksQuery()}>Get chunks</button>
                    </div>

                </div>

                <div className="bg-slate-400 w-11/12 overflow-y-scroll overflow-x-scroll max-h-[80vh] min-h-[40vh] self-center">
                    <h1>View Chunks</h1>
                  { loading===true
                    ?<div className="flex justify-center">
                        <Loader />
                    </div>
                    :<div>{chunks}</div>

                  }
                    

                </div>

            </div>
        
        </div>
    )
}

export default ViewChunks;