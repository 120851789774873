import * as React from "react"
import {flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, ChevronRight, MoreHorizontal, ChevronLeft } from "lucide-react"
import { Button } from "@/src/components/ui/button"
import { Checkbox } from "@/src/components/ui/checkbox"
import {DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger} from "@/src/components/ui/dropdown-menu"
import {Table, TableBody, TableCaption, TableCell, TableHead, TableFooter, TableHeader, TableRow} from "@/src/components/ui/table"
import { TableToolbar } from "./tableToolBar"
import {columns} from "./columns"
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/src/components/ui/pagination"
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, } from "@/src/components/ui/select"
import './TableCss.css'
import Pagin from "./pag"
const data = [
    {
      id: "m5gr84i9",
      case_title: "",
      url:'',
      jurisdiction: "",
      country:'',
      decision_date:'',
      judgment:'',
      judgment_text:'',
      score:''
    },

  ]
  

  
const DemoTable = (props)=> {
    const [sorting, setSorting] = React.useState([])
    const [columnFilters, setColumnFilters] = React.useState([])
    const [columnVisibility, setColumnVisibility] = React.useState({})
    const [rowSelection, setRowSelection] = React.useState({})
    const [activeRow, setActiveRow] = React.useState(null)


    const toggleActiveRow = (rowId) => {
      if(window.getSelection().toString().length==0){
        console.log('nothing selected')
        if(activeRow){
          const x = document.getElementById(activeRow)
          if (x){
            x.classList.add('elipsis-row')
            x.classList.remove('elipsis-row-focused')
          } else{
            setActiveRow(null)
          }
        
        }
        if(activeRow==rowId){
          setActiveRow(null)
        }else{
          console.log(rowId)
          const x = document.getElementById(rowId)
          x.classList.add('elipsis-row-focused')
          x.classList.remove('elipsis-row')
          setActiveRow(rowId)
        }
      }

    }

    React.useEffect(()=>{
      props.setSelectedRows(rowSelection)
    },[rowSelection])


    const table = useReactTable({
      data:props.tableData ?? data,
      columns,
      onSortingChange: setSorting,
      onColumnFiltersChange: setColumnFilters,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      onColumnVisibilityChange: setColumnVisibility,
      onRowSelectionChange: setRowSelection,
      state: {
        sorting,
        columnFilters,
        columnVisibility,
        rowSelection
      },
      initialState: {
        pagination: {
            pageSize: 10,
        }},
    })
  
    return (
      <div className="w-full h-full">
        {/*Table Body */}

        <div className="rounded-md max-h-10% ">
          <TableToolbar table={table} handleTableSearch={props.handleTableSearch} handleShowChat={props.handleShowChat}/>
          <Table id='table'>
            <TableHeader className='bg-slate-200 sticky top-12  shadow-sm shadow-black z-1'>
              
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
        
            <TableBody
            className=' overflow-y-scroll'>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map(row => (
                  <TableRow
                    className='elipsis-row'
                    id = {'row_id'+row.id}
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => toggleActiveRow('row_id'+row.id)}
                    
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell className='' key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
      
          </Table>

        {/* Table Footer */}

        </div>

    
        <div className="flex items-center justify-end space-x-2 py-2 mt-auto sticky bottom-0 bg-white">

        <div className="ml-4 text-xs text-muted-foreground">
          Rows per page

          </div>


          <Select onValueChange={(value)=>{table.setPageSize(value)}}>
          <SelectTrigger className="w-16">
            <SelectValue placeholder="10" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Rows per page</SelectLabel>
              <SelectItem value={10}>10</SelectItem>
              <SelectItem value={25}>25</SelectItem>
              <SelectItem value={50}>50</SelectItem>
              <SelectItem value={75}>75</SelectItem>
              <SelectItem value={100}>100</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>    


          <div className="flex-1 text-xs text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} row(s) selected.
          </div>



          <div className="space-x-2 pr-2 flex">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeft className="h-4 w-4 mr-2" />
              Previous
            </Button>
            <Pagin table={table}/>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
              <ChevronRight className="h-4 w-4 ml-2" />
            </Button>
          </div>
        </div>
      </div>
    )
  }
  
  export {DemoTable}