import '../../CSS/Topbar.css'
import {Link} from 'react-router-dom'



const UserTopBar = (props) =>{
    return(
        <div className='TopBar'>


            <Link to='/panel/testChat' onClick={(e)=>props.handleSelectedRoute('test-chat')}>
                <button className='Topbar-button Selected' id='test-chat'>AI Cases</button>
            </Link>


            <Link to='/panel/conversations' onClick={(e)=>props.handleSelectedRoute('conversation')}>
                <button className='Topbar-button' id='conversation'>Chat History</button>
            </Link>




        </div>
    )
}

export default UserTopBar;