import { useState, useEffect } from "react";
import {getInfoSources, updateInfoSources} from "../api/infosourcesApi";
import { addTask } from "../../chatbot_tasks/tasksApi";
import Loader from "../../../components/other/Loader";
import InfoSourceTable from "./tableInfoSources";
const InfoLinks = (props)=>{
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeList, setActiveList] = useState([])
    const [links, setLinks] = useState('')
    const [infoSources, setInfoSources] = useState(null)

    const handleUpdateActiveSources=()=>{
        const data = {
            'chatbot_id':props.chatbot.value,    
            'active':activeList
        }
        updateInfoSources('active', data)
    }
    
    const handleSelectAllActive=()=>{
        console.log(activeList)
        const active = []
        for( var i in infoSources){
            console.log(infoSources[i].id)
            active.push(infoSources[i].id)

        }
        setActiveList(active)

    }

    const handleDeselectAllActive=()=>{
        setActiveList([])

    }

    const InfoSourcesTask = ()=>{
        const task = {
            'chatbot_id' : props.chatbot.value,
            'type' : 1,
            'parameters' : 'test',
            'state':0,
            'command':0,

        }
        return task
    }
    const createInfoSources = async()=>{
        const task = InfoSourcesTask()
        await addTask(task).then(
            (response)=>{
                fetchInfoSources()
            }
        )
        

    }

    const fetchInfoSources = async()=>{
        await getInfoSources(props.chatbot.value).then(
            (response)=>{
                if(response.length>0){
                    setInfoSources(response)
                }else{
                    setInfoSources([{
                        'id':'',
                        'name':'',
                        'updated':'',
                        'active':'',
                        'select':'',
                        
                    }])
                }

            }
        )
    }

    useEffect(()=>{
        if(props.chatbot && props.chatbot.value !== 'None' && props.chatbot.value !=='none'){
            console.log('fetching info source')
           fetchInfoSources() 
        }
        
    },[props.chatbot])
    return(
        <div className="bg-slate-200 m-4 w-[95%]">
        <h1>Info Sources</h1>
        <div id='links' disabled={!edit} value={links} onChange={(e)=>setLinks(e.target.value)} className="w-[100%] h-4/5 overflow-y-scroll resize-none">
            <div className="w-[100%]">
                <InfoSourceTable infoSources={infoSources} setActiveList={setActiveList}/>
            </div>

        
            
        </div>

        {
            loading
            ?   <div>
                    <Loader />
                </div>
            :<div>
            <button className="Action-button"onClick={()=>createInfoSources()} >Create Info sources</button>    
            <button className="Action-button"onClick={()=>fetchInfoSources()} >Get Info Sources</button>
            <button className="Action-button"onClick={()=>handleUpdateActiveSources()} >Update Active Sources</button>
            <button className="Action-button"onClick={()=>handleSelectAllActive()} >Activate all</button>
            <button className="Action-button"onClick={()=>handleDeselectAllActive()} >Deactivate all</button>


        </div>
        }
        
        
        </div>
    )
}

export default InfoLinks;