import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../components/other/Urls";
const api_url = Api_url()
async function testChatbot(chatbot){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'test',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'chatbot' : chatbot,

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

export default testChatbot;