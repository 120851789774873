import { useState, useEffect } from "react";
import ConversationList from "./components/conversationList";
import ConversationDisplay from "./components/conversationDisplay";
import { useAppState } from "../../hooks/AppState";
import Loader from "../../components/other/Loader";
const ConversationPage = (props)=>{
    const [conversation, setConversation] = useState()
    const appState = useAppState()
    const test = false
    useEffect(()=>{{
        setConversation()
    }},[appState.chatbot])

    if(appState.chatbot.data !== undefined){

    

    return(
        <div className="PageContainer flex-row">

            <ConversationList chatbot={appState.chatbot} setConversation={setConversation}/>

            <ConversationDisplay conversation={conversation}/>


        </div>
    )
    }else{
        return(

            <Loader/>

        )
    }
}

export default ConversationPage;