import { useState, useEffect } from "react"
import CoreConfig from "./components/coreConfig"
import CacheSettings from "./components/cacheConfig"
import PasrserConfig from "./components/parserConfig"
import handleActiveButton from "../../functions/handleActiveButton"
import { useAppState } from "../../hooks/AppState"
import Loader from "../../components/other/Loader"
import '../../App.css'
const ChatbotCore = ()=>{
    const[page, setPage] = useState('conf-core')
    const appState = useAppState()
    const handlePage=(newPage)=>{
        setPage(newPage)
        handleActiveButton(newPage, page)
    }
    
    
    if(appState.chatbot.data !== undefined){

    
    return(
        <div className="PageContainer">
            
            <div className='flex flex-row bg-slate-400'>
                <button className="Action-button-selected" value='conf-core' id='conf-core' onClick={(e)=>handlePage(e.target.value)}>Chatbot Core</button>
                <button className='Action-button' value='conf-cache' id='conf-cache' onClick={(e)=>handlePage(e.target.value)}>Chatbot Cache</button>
                <button className='Action-button' value='conf-parser' id='conf-parser' onClick={(e)=>handlePage(e.target.value)}>Chatbot Parser</button>
            </div>

            

            <div className="self-center flex flex-col bg-slate-200 w-1/2 mt-5 rounded-md p-2">
 
            <div>
                <CoreConfig show={page=='conf-core'}/>
                <CacheSettings show={page=='conf-cache'}/>
                <PasrserConfig show={page=='conf-parser'}/>
            
            </div>
         
            </div>           
        </div>
    )}else{
        return(

            <Loader/>
    )}

}

export default ChatbotCore;