const environment = 'prod'
const Api_url = () =>{
    if(environment==='local'){
        return 'http://127.0.0.1:8000/'
    }

    else if(environment==='dev'){
        return 'https://192.168.150.1:8090/'
    }

    else if(environment==='prod'){
        return 'https://www.panel.aizuzi.com/'
    }
}

export default Api_url;