import "../../CSS/Login.css"
import {Link} from 'react-router-dom'
import { useState } from "react"
import { PostRequest } from "../../functions/requestTemplates"
import { useAppState } from "../../hooks/AppState"
import { useAuth } from "../../hooks/Authenticate"
const ManageUserPage = () =>{
    const appState = useAppState()
    const auth = useAuth()








    return(
        <div className="PageContainer">
        <div className="flex w-full justify-center">
            <div className="Form-container p-0 mt-0 flex flex-col justify-normal">
                <h1 className="">User Profile</h1>
                
  
                <label className="mr-auto">E-mail</label>
                <input className="Form-input" id='email' value={auth.user} disabled></input>


                <Link to='/panel/change_password'>
                <button className="Form-button">Change Password</button>
                </Link>
                
                    
                

            </div>
        </div>
    
    </div>)
}

export default ManageUserPage;