import React from 'react'
import { Navigate, Outlet , useNavigate} from 'react-router-dom';
import { useAuth } from '../../hooks/Authenticate';
const PrivateRoute = (auth) => {

    const user = useAuth()

    return user.token ? <Outlet /> : <Navigate replace={true} to="/panel/login" />;
}

export default PrivateRoute;