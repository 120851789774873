import "../../CSS/Login.css"
import { useState } from "react"
import { PostRequest } from "../../functions/requestTemplates"
import { useAuth } from "../../hooks/Authenticate"
import { Link } from "react-router-dom"
const CreateUserPage = () =>{
    const [registerForm, setRegisterForm]= useState({email:'', username:'', password:''})
    const [focused, setFocused] = useState(null)

    const handleRegisterInput = (e)=>{
        const name = e.target.name
        const value = e.target.value
        setRegisterForm((prev)=>({...prev, 
            [name]: value
        }))
    }


    const verify_email = (email)=>{
        if(email !== ''  && email.indexOf('@')>-1 && email.indexOf('.')>-1){
            console.log('email is valid')
            return true
        }else{
            console.log('mail is invalid')
            return false
        }
    }




    async function submitRegister (e){
        e.preventDefault()
        
        if(registerForm.email !== ''  && registerForm.email.indexOf('@')>-1 && registerForm.email.indexOf('.')>-1){
            const email_check = true
            console.log('email is valid')
            const request = await PostRequest('admin/create_user/', registerForm)
        }else{
            console.log('mail is invalid')
            const email_check = false
        }
        



}




    return(
        <div className="PageContainer">
        <div className="flex w-full justify-center">
            <div className="Form-container">
                <form className="flex flex-col" onSubmit={(e)=>{submitRegister(e)}}>
                    <input className="Form-input" onFocus={()=>setFocused('e-mail')} id='e-mail' type="text" name='email' placeholder="E-mail" onChange={e =>handleRegisterInput(e)}></input>

                    <button className="Form-button" type='submit'>Create Account</button>
                    
                </form>

            </div>
        </div>
    
    </div>)
}

export default CreateUserPage;