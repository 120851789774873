import './App.css';
import {Route, Routes, Navigate} from "react-router-dom"
import { useState, useEffect} from 'react';
import Sidebar from './components/sidebar/Sidebar';
import TopBar from './components/topbar/TopBar';
import DashboardPage from './pages/dashboard/dashboard';
import CachePage from './pages/cache/cache';
import CreateProjectPage from './pages/project_create/createProject';
import TestChatPage from './pages/chatbot_test/chatbotTest';
import LoginPage from './pages/login/login';
import RegisterPage from './pages/login/register';
import CreateChatbotPage from './pages/chatbot_create/createPage';
import ChatbotDatabase from './pages/chatbot_parser/databasePage';
import ChunksPage from './pages/chunks/chunksPage';
import TasksPage from './pages/chatbot_tasks/tasksPage';
import ChatbotCore from './pages/chatbot_config/configPage';
import ProjectSettings from './pages/project_settings/pages/projectSettings';
import PrivateRoute from './components/custom_routes/PrivateRoute';
import AdminRoute from './components/custom_routes/AdminRoute';
import FirstLoginRoute from './components/custom_routes/FirstLoginRoute';
import ConversationPage from './pages/conversations/conversationPage';
import QandA from './pages/Q&A/Q&A';
import FunctionsPage from './pages/chatbot_functions/functionsPage';
import CreateUserPage from './pages/user_create/userCreatePage';
import AdminManageUserPage from './pages/user_manage/adminUserManage';
import ChangePasswordPage from './pages/user_manage/userChangePassword';
import ManageUserPage from './pages/user_manage/userManage';
import { useAuth } from './hooks/Authenticate';
import { useAppState } from './hooks/AppState';
import ChatInterface from './pages/chatbot_test/pages/ChatWindow';
function App() {
  const [selectedRoute, setSelectedRoute] = useState('test-chat')
  const auth = useAuth()
  const appState = useAppState()

  useEffect(()=>{
    if(auth.user && auth.token && auth.userType){
      appState.setLogged(true)
      if(auth.userType === 'Admin'){
        appState.setAdminUser(true)
    }}
    
  },[auth.user])


  return (
    <div className="App">

 


      <div className='grid-sidebar'>
            {<Sidebar  selectedRoute={selectedRoute} setSelectedRoute={setSelectedRoute} />}
      </div>


      <div className='grid-topbar'>
            {<TopBar   selectedRoute={selectedRoute} setSelectedRoute={setSelectedRoute} />}
      </div>
        
      

      <div className='grid-main'>
    <Routes>

    
      <Route path='/panel/login' element={<LoginPage/>}/>
      <Route path='/panel/register' element={<RegisterPage/>}/>


      <Route path='/panel/' element = {<PrivateRoute/>}>
        <Route path='/panel/' element = {<TestChatPage/>} />
      </Route>

      <Route path='/panel/project_settings' element = {<AdminRoute/>}>
        <Route path='/panel/project_settings' element = {<ProjectSettings/>}/>
      </Route>

      <Route path='/panel/chatbot_core' element = {<AdminRoute/>}>
        <Route path='/panel/chatbot_core' element = {<ChatbotCore/>} />
      </Route>

      <Route path='/panel/chatbot_database' element = {<AdminRoute/>}>
        <Route path='/panel/chatbot_database' element = {<ChatbotDatabase/>} />
      </Route>

      <Route path='/panel/chatbot_cron' element = {<AdminRoute/>}>
        <Route path='/panel/chatbot_cron' element = {<TasksPage/>} />
      </Route>

      <Route path='/panel/create_chatbot' element = {<AdminRoute/>}>
        <Route path='/panel/create_chatbot' element = {<CreateChatbotPage/>} />
      </Route>


      <Route path='/panel/create_project' element = {<AdminRoute/>}>
        <Route path='/panel/create_project' element ={<CreateProjectPage/>}/>
      </Route>


      <Route path='/panel/testChat' element = {<PrivateRoute/>}>
          <Route path='/panel/testChat' element={<TestChatPage />}/>
      </Route>

      <Route path='/panel/chunks' element = {<AdminRoute/>}>
        <Route path='/panel/chunks' element={<ChunksPage />}/>
      </Route>

      <Route path="/panel/cache" element = {<PrivateRoute/>}>
          <Route path="/panel/cache" element={ <CachePage />}/>
      </Route>

      <Route path="/panel/conversations" element = {<PrivateRoute/>}>
          <Route path="/panel/conversations" element={ <ConversationPage />}/>
      </Route>

      <Route element = {<PrivateRoute/>}>
          <Route path="/panel/QandA" element={ <QandA/>}/>
      </Route>

      <Route path="/panel/chatbot_functions" element = {<PrivateRoute/>}>
          <Route path="/panel/chatbot_functions" element={ <FunctionsPage/>}/>
      </Route>

      <Route path='/panel/create_user' element = {<AdminRoute/>}>
        <Route path='/panel/create_user' element={<CreateUserPage />}/>
      </Route>

      <Route path='/panel/manage_user' element = {<AdminRoute/>}>
        <Route path='/panel/manage_user' element={<AdminManageUserPage />}/>
      </Route>

      <Route path='/panel/change_password' element = {<PrivateRoute/>}>
        <Route path='/panel/change_password' element={<ChangePasswordPage />}/>
      </Route>

      <Route path='/panel/profile' element = {<PrivateRoute/>}>
        <Route path='/panel/profile' element={<ManageUserPage />}/>
      </Route>


      
      <Route path='*' element = {<PrivateRoute/>}>
        <Route path="*" element={<Navigate to="/panel/" replace />} />
      </Route>
    </Routes>
    </div>

    </div>
  );
 


  
}

export default App;

