import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../components/other/Urls";
const api_url = Api_url()

async function GetProjectData(project_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'project_manage/?project_id='+project_id,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Authorization': token,
        },
       })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}

async function GetProjectDataOg(project_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'cms_project_manage/?project_id='+project_id,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Authorization': token,
        },
       })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}

async function UpdateProjectData(project_data){
    const token = String(Cookies.get('token'))



    return await fetch(api_url+'project_manage/',{
        method:'PATCH',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':project_data

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}

async function UpdateProjectDataOg(project_data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'cms_project_manage/',{
        method:'PATCH',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'project_data':project_data

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}


async function DeleteProject(project_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'project_manage/',{
        method:'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'project_id':project_id

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

async function DeleteProjectOg(project_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'cms_project_manage/',{
        method:'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'project_id':project_id

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

export {GetProjectData, UpdateProjectData, DeleteProject};