import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
  } from "@/src/components/ui/pagination"


const Inner_pagin =({table, current_page})=>{
    const total_pages = table.getPageCount()


    const setSecond = ()=>{
        if (current_page < 1){
            table.setPageIndex(1)}
        else if (current_page > total_pages-2){
            table.setPageIndex(total_pages-2)
        }
        else{
            table.setPageIndex(current_page)
        }
    }

    return(
        <>
        <PaginationItem>
            <PaginationLink href="#" value={3} onClick={()=>setSecond()} isActive={current_page>=1 && current_page<=total_pages-2}>
            {current_page <= 1 ? 2
            : current_page >= total_pages-1 ? total_pages-1
            : current_page+1}
            </PaginationLink>
        </PaginationItem>
        </>
    )
}

const Pagin = ({table})=>{
    const current_page = table.getState().pagination.pageIndex


    return(
        <Pagination>
            <PaginationContent>

            <PaginationItem>
                <PaginationLink href="#" isActive={current_page===0} onClick={()=>table.firstPage() }>1</PaginationLink>
            </PaginationItem>

            {table.getPageCount() > 2 && table.getState().pagination.pageIndex > 1
                    ?
                            <PaginationItem>
                                <PaginationEllipsis />
                            </PaginationItem>
                    :
                    <></> 
            }

            {table.getPageCount()>1
            ?<Inner_pagin table={table} current_page={current_page}/>
            :<></>
            
            }

            {table.getPageCount() > 3 && table.getState().pagination.pageIndex < table.getPageCount()-2
                    ?
                            <PaginationItem>
                                <PaginationEllipsis />
                            </PaginationItem>
                    :
                    <></> 
            }
            {table.getPageCount()>2
            ?
            <PaginationItem>
                <PaginationLink href="#" onClick={()=>table.lastPage()} isActive={current_page===table.getPageCount()-1} >{table.getPageCount()}</PaginationLink>
            </PaginationItem>

            :<></>
           }

            </PaginationContent>
        </Pagination>
    )
}

export default Pagin;