import { useState, useEffect } from "react";
import AddChunks from "./components/addChunks";
import ViewChunks from "./components/viewChunks";

const ChunksPage = (props)=>{
    const [selectedPage, setSelectedPage]=useState('view')


    return(
        <div className="PageContainer">

            <div className='flex flex-row bg-slate-400'>
                <button className='Action-button-selected' value='view' onClick={(e)=>setSelectedPage(e.target.value)}>View Chunks</button>
            </div>
            <AddChunks hide={selectedPage!=='add'}/>
            <ViewChunks hide={selectedPage!=='view'} chatbot={props.chatbot} project={props.project} chatbotDropdown={props.chatbotDropdown}/>

        </div>
    )
}

export default ChunksPage;