import { useState, useEffect } from "react"
import TaskTable from "./componenets/TaskTable";
import { getTasks, addTask, updateTasks } from "../tasksApi";
import { useAppState } from "../../../hooks/AppState";
const ManagePage =(props)=>{
    const [tasks, setTasks] = useState()
    const [autoRefresh, setAutoRefresh] = useState(false)
    const appState = useAppState()
    let interval = null
    const fetchTime = 15

    const fetchTasks = async()=>{
        const tasks = await getTasks(appState.chatbot.value)
        if(tasks['data'].length>0){
        setTasks(tasks['data'])
    }else{
        setTasks(null)
    
    }}

    const handleDivCheckBox=()=>{
        if(autoRefresh){
            setAutoRefresh(false)
        }else{
            setAutoRefresh(true)
        }         
    }

    const addTestTask = async()=>{
        const task = {
            'chatbot_id' : appState.chatbot.value,
            'type' : 101,
            'parameters' : 'test',
            'state':0,
            'command':0,

        }
        const tasks = await addTask(task)
        fetchTasks()
        
    }
    
    useEffect(()=>{
        clearInterval(interval)
        interval = setInterval(()=>{
            if(autoRefresh){
                fetchTasks()
            }},
            fetchTime*1000)
        return () => clearInterval(interval)
    },[autoRefresh])


    useEffect(()=>{
        fetchTasks()
    },[])
    return(
        <div hidden={props.hidden}>
            <div className=" flex flex-col justify-center bg-slate-300">
                <div className="flex flex-row self-center">
                        <button className="Action-button" onClick={()=>fetchTasks()}>Fetch Tasks</button>
                        <button className="Action-button" onClick={()=>addTestTask()}>Add Test Task</button>
                        <div className="Action-button" onClick={()=>handleDivCheckBox()}>
                        <label className="mr-2" >Auto-refresh</label><input type="checkbox" checked={autoRefresh} readOnly/>
                        </div >
                </div>

                <TaskTable tasks={tasks}/>
            </div>

        </div>
    )
}
export default ManagePage;