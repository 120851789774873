import { useAppState } from "../../../hooks/AppState"
import { useState, useEffect } from "react"
const ChatbotSelect = () =>{
    const AppState = useAppState()
    const [selected, setSelected] = useState('None')

    const setActiveChatbot = async(e)=>{
        const new_value = e.target.value    
        const chatbotName = e.target.selectedOptions[0].text 
        if (AppState.chatbot.value!==new_value){
            setSelected(new_value)
            console.log('not the same')
            AppState.setChatbot({'name':chatbotName, 'value':new_value})
        }
        
    }

    useEffect(()=>{

        setSelected(AppState.chatbot.value)

    },[AppState.chatbot.value])




    return(
            <select id='chatbotSelect' value={selected} onChange={(e) =>setActiveChatbot(e)} className='Sidebar-select' defaultValue={"Select Chatbot"}>
                <option className="Sidebar-option" disabled value="None">Select Chatbot Version</option>
                {AppState.chatbotDropdown}
            </select>
    )
}

export default ChatbotSelect;