import CopyToExistingComponent from "./copy_components/copyToExistingComponent";
import CopyToNewComponent from "./copy_components/copyToNewComponent";
import { useEffect, useState } from "react";
import { DeleteProject } from "../projectSettingsApi";
import { useAppState } from "../../../hooks/AppState";
const ProjectDeleteOverlay = (props)=>{
    const appState = useAppState()

    const handleCancel=()=>{
        props.setShow(false)
    }
    async function handleDelete(){
        await DeleteProject(appState.project.value)
        appState.updateSession()
        props.setShow(false)

    }
 
    return(
    <div hidden={props.hide}>

   
    <div  className=" w-[100%] h-[100vh] fixed left-0 top-0 bg-gray-600 bg-opacity-50 ml-[210px] flex justify-center">
        <div className="bg-slate-300  border-2 border-slate-950 w-1/3 h-fit fixed left-[40%] top-1/3">
            
                 
            
            <div>
                 
            </div>
            <div className='flex flex-col bg-slate-300 justify-center'>
                <h1>Are you sure you want to delete {appState.project.name} ?</h1>
                <h1>This operation cannot be undone</h1>
                <div>
                    <button className="Action-button"  onClick={()=>handleCancel()}>Cancel</button>
                    <button className='Action-button' value='delete' onClick={()=>handleDelete()}>Delete</button>
                </div>

                
                </div>


        </div></div> </div>
        )
}

export default ProjectDeleteOverlay;