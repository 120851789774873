import { useEffect, useState } from "react"

const DataRow = (Data) =>{
    const [finishedRender, setFinshedRender] = useState(false)
    const setRows = Data.RowSetter //parent setState from cache.js for getting row objects
    let data = Data
    let rowElements = []
    let rowIds =[]
    let rowElementIds = []

    const createRows = (rows) =>{
        // takes a list of lists of row data and converts them into rows
        rows.map((row, i)=>{
            rowIds = []
            rowElements.push(
                            <tr key={i+1}>
                                <td><input id={i+' Select'} type="Checkbox"></input></td>
                                {createColumns(row, i)}
                            </tr>)
            rowIds.push(i +' Select')
            rowElementIds.push(rowIds)
        })
       if (finishedRender === false){
        setFinshedRender(true)
       }       
    }


    const createColumns = (row, columnid)=>{
        // converts a list of row data into row entries
        const dataRow = row.map((entry, i)=>{
            let key = (''+columnid+'.'+i)
            rowIds.push(key)
            return(
                    <td key={key} className=" w-[200%]">
                        <textarea className="CacheDataCell" id={key} type="text" defaultValue={entry}/>
                    </td>
                    )

        })
        return dataRow
    }
    
    if (data.RowsData !== undefined){
        createRows(data.RowsData)
        
    }

    useEffect(()=>{      
        setRows(rowElementIds)
            
    },[finishedRender])

    return(

            <tbody>
                {rowElements}
            </tbody>
 
    )
}

export default DataRow