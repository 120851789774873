import CacheTable from './table';
import './table.css'
import { Get_cache, Save_cache } from '../api/cache_api';
import { useState, useEffect } from "react";
const CachePreview = (props)=>{
    const [Headers, setHeaders] = useState()
    const [Data, setData] = useState()
    const [rowsData, setRowsData] = useState()
    const [deleteList, setDeleteList] = useState()
    const undoChanges = ()=>{
        Get_cache('TEST', {setHeaders}, {setData})
    }

    const getRowData = (rows)=>{
        let all_items=[]
        let i = 0
        while(i<rows.length){
            let x = 0
            let row_dict = {}
            while(x<rows[i].length){  
                let item = document.getElementById(rows[i][x])
                let key = Headers[x]
                row_dict[key] = item.value
                x = x+1

            }
            all_items.push(row_dict)
            i = i+1
        }
        return all_items
    }

    const save_database = ()=>{
        const save_data = getRowData(rowsData)
        Save_cache('TEST',save_data)
    }

    const deleteRows = (rows) =>{
        let i=0
        let for_deletion = []
        let delete_vector_ids = []
        let doDelete = false

        while(i<rows.length){

            let row = rows[i]
            const row_len = row.length
            let selectid = row[row_len-1] 

            let select_obj = document.getElementById(selectid)
            if (select_obj == null){
                return
            }
            if(select_obj.checked){
                doDelete = true
                let row_object = select_obj.parentElement.parentElement
                row_object.parentElement.removeChild(row_object)
                for_deletion.push(i)
                //
            }
            i = i+1}


       if(doDelete){
            let x = 0
            while(x<for_deletion.length){
                let position = for_deletion[x]
                rows.splice(position-x, 1)
                x = x+1  
        
            }
            setDeleteList(for_deletion)
        }
    }


    useEffect(()=>{
        Get_cache(props.chatbot.value, {setHeaders}, {setData})        
    },[])
    return(
        <div hidden={!props.show}>

            <div className="TableActionDiv">
                <button className="TableActionButton" onClick={()=>save_database()} >Save</button>
                <button className="TableActionButton" onClick={()=>undoChanges()}>Undo Changes</button>
                <button className="TableActionButton" onClick ={()=>deleteRows(rowsData)}>Delete Selected</button>
                <button className="TableActionButton">clear cache</button>
                <button className="TableActionButton">Auto-clear options</button>

            </div>
            

            <div className="flex justify-center">
                <CacheTable hidden={true} Headers={Headers} Data={Data} RowState={setRowsData} />
            </div>

        </div>

    )
}

export default CachePreview;