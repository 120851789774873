import Table from "./Q&A-Table";
import QuestionComponent from "./Q&A-Questions";
import CreateQuestion from "./Q&A-CreateQuestion";
import MoveQuestions from "./Q&A-MoveQuestion";
import CopyQuestions from "./Q&A-CopyQuestion";
import { useEffect, useState } from "react";
import { DeleteRequest } from "../../../functions/requestTemplates";
const CollectionView = (props) => {
    const [selected, setSelected] = useState([])
    const [activeCollection, setActiveCollection] = useState({id:null, data:null})
    const [selectAll, setSelectAll] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [search, setSearch] = useState('')

    const handleDelete = async()=>{
        if (selected.length>0){
            await DeleteRequest('question_answer/', {chatbot_id:props.chatbot_id, collection_id:activeCollection.id, 'id':selected})
            afterActionCleanup()
        }else{
            alert('No questions selected')
        }
    }

    const afterActionCleanup = ()=>{
        setActiveCollection({id:null, data:null})
        setSelected([])
        setSelectAll(false)
        props.refresh()
    }

    const handleSelectAll = ()=>{
        
        if (selectAll === false){
            setSelectAll(true)
        }else{
            setSelectAll(false)
        }
    }

    const handleSelectedOption = (option)=>{
        if(selectedOption === option){
            setSelectedOption(null)
        }else{
            setSelectedOption(option)
        }
        
    }

    const populateTable = (array)=>{
        if(array.length>0){
            return array.map((data)=>{
                if(data.question.includes(search)){
                    return <QuestionComponent question={data.question} answer={data.answer} action={data.action} qa_collection={data.qa_collection} id={data.id} setSelected={setSelected} selectAll={selectAll}/>
                }
            })
        }
    }
    
    useEffect(()=>{
        setSelectAll(false)
        setSelectedOption(null)
        setActiveCollection(props.collection)
        
    },[props.collection])
    
    return(

            <div className="flex flex-col w-[100%] max-h-[94vh] bg-slate-300">
                
                <div className="flex flex-row w-[100%] min-h-32 max-h-[6%] bg-slate-400">
                    <h1 className="mr-2 pt-2" >Search:</h1>
                    <input className="m-2 rounded-sm" value={search} onChange={(e)=>setSearch(e.target.value)}></input>
                    <button className="Action-button bg-green-300" onClick={()=>handleSelectedOption('Create')}>Add Question</button>
                    <button className="Action-button bg-yellow-300" onClick={()=>handleSelectAll()}>{selectAll? "Deselect all" : "Select All"  }</button>
                    <button className="Action-button bg-blue-300" onClick={()=>handleSelectedOption('Move')}>Move Selected</button>
                    <button className="Action-button bg-blue-300" onClick={()=>handleSelectedOption('Copy')}>Copy Selcted</button>
                    <button className="Action-button bg-red-300" onClick={()=>handleDelete()}>Delete Selected</button>

                </div>
                {
                    selectedOption == 'Create'? <CreateQuestion collectionId={activeCollection.id} refresh={props.refresh}/> : null
                }

                {               
                    selectedOption == 'Move'? <MoveQuestions refresh={afterActionCleanup} collectionList={props.collectionList} selected={selected} activeCollection={activeCollection}/> : null
                }

                {               
                    selectedOption == 'Copy'? <CopyQuestions refresh={afterActionCleanup} collectionList={props.collectionList} selected={selected} activeCollection={activeCollection}/> : null
                }

                

                <Table/>
                <div className=" max-h-[100%] overflow-y-auto [scrollbar-width:thin]">
                {
                    activeCollection.data !== null && activeCollection.data !==undefined? populateTable(activeCollection.data.data) : null
                }
                    
                    
                </div>
            </div>
        
 

    )
}

export default CollectionView;