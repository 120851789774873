import { useState, useEffect } from 'react'
import GetProjects from '../../../functions/requstProjects'
import { useAppState } from '../../../hooks/AppState'
const ProjectSelect = ()=>{
    const [projects, setProjects] = useState([])
    const [selected, setSelected] = useState(0)
    const appState = useAppState()

    const setActiveProject = (e) =>{
            if (appState.project.value !== e.target.value){
                setSelected(e.target.value)
                const activeProject = {'name' : e.target.name, 'value': e.target.value}
                appState.setProject(activeProject)
                }
    }

    async function CreateProjectDropdown(){
        let projects = []
        const project = await GetProjects()
        for(const item in project){
            const array = project[item]
            const element =(
                            <option key={item} className='Sidebar-option' name={array[0]} value={array[1]}>{array[0]}</option>
                            )
            projects.push(element)       
        }
        setProjects(projects)
    }

    useEffect(()=>{
        CreateProjectDropdown()
        if(appState.project.value !== 'None'){
            setSelected(appState.project.value)
        }
    },[appState.project.value])

    

    return(
        <select className='Sidebar-select' value={selected} onChange={(e)=>setActiveProject(e)}>
            <option value={0} className='' disabled >Select Project</option>
            {projects} 
        </select>  
    )
}

export default ProjectSelect;