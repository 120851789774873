import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../../components/other/Urls";
const api_url = Api_url()
async function Get_cache(bot_id, {setHeaders}, {setData}){
    const token = String(Cookies.get('token'))
    await fetch(api_url+'cache_functions/?chatbot_id='+bot_id,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken':cookie.load('csrftoken'),
            'Content-Type': 'application/json',
            'Authorization': token,
            },})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            const cache = json.data
            if( cache[0] !== undefined){
     
            const headers = Object.keys(cache[0])
            const data = []
            
            let i = 0
            while(i < cache.length){
                let values = (Object.values(cache[i]))
                i = i+1
                data.push(values)
            }
            setHeaders(headers)
            setData(data)}
        })
    }
    
async function Save_cache(bot_id, cache){
    const token = String(Cookies.get('token'))
    await fetch(api_url+'cms_functions/',{
        method:'patch',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'Chatbot_ID' : bot_id,
            'Cache': cache,


        })})
        .then((response)=>{
            return response.json()
        })

    }



export {Get_cache, Save_cache};