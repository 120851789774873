import { useState, createRef, useEffect } from "react"
import { Navigate, redirect, useNavigate } from "react-router-dom"
import { getEmbedders } from "../../chatbot_create/api/createChatbotApi"
import { updateChatbotData } from "../api/chatbotDataApi"
import { useAppState } from "../../../hooks/AppState"
const CoreConfig = (props)=>{
    const [chatbotName, setChatbotName] = useState()
    const [embedder, setEmbedder] = useState('OpenAI')
    const [welcomeMessage, setWelcomeMsage] = useState()
    const [unknownMessage, setUnknownMesage] = useState()
    const [prompt, setPrompt] = useState()
    const [dataThreshold, setDataThreshold] = useState()
    const [cacheThreshold, setCacheThreshold] = useState()
    const [chatbotType, setChatbotType] = useState()
    const [embeddersList, setEmbeddersList] = useState()
    const [edit, setEdit] = useState(false) 
    const [loader, setLoader] = useState(false)

    const navigate = useNavigate()
    const appState = useAppState()

    const formValid = () =>{
        return true
    }

    const toggleEdit = ()=>{
        const save_btn = document.getElementById('save_btn')
        const cancel_btn = document.getElementById('cancel_btn')
        const scrapper_btn = document.getElementById('scrapper_btn')
        if(edit){
            cancel_btn.classList.add('hidden')
            save_btn.classList.add('hidden')
   
            setEdit(false) 
        }else{
            cancel_btn.classList.remove('hidden')
            save_btn.classList.remove('hidden')
            
            setEdit(true)

        }

    }

    async function showEmbedders(){       
        let embedder_list = []
        const embedder = await getEmbedders(setLoader)

        for(const i in embedder){
            const array = embedder[i]
            const element = <option key={i} name={array[0]} value={array[1]}>{array[0]}</option> 
            embedder_list.push(element)
                 
        }
        setEmbeddersList(embedder_list)}
    

    async function formSubmit (event){
        event.preventDefault()
        if (formValid()){
            const chatbot_config = { 
                'chatbot_id':appState.chatbot.value,
                'chatbot_name': chatbotName,
                'embedder_id': embedder,
                'prompt': prompt,
                'welcome_message': welcomeMessage,
                'unknown_message': unknownMessage,
                'data_threshold':dataThreshold,
                'chatbot_type': chatbotType
            }
            updateChatbotData(chatbot_config)
            toggleEdit()
            appState.setChatbot({'name':chatbotName, 'value':appState.chatbot.value})
            appState.getSession()

        }

    }

    async function requestChatbotData(){
        setChatbotName(appState.chatbot.data['chatbot_name'])
        setEmbedder(appState.chatbot.data['embedder_id'])
        setPrompt(appState.chatbot.data['prompt'])
        setWelcomeMsage(appState.chatbot.data['welcome_message'])
        setUnknownMesage(appState.chatbot.data['unknown_message'])
        setDataThreshold(appState.chatbot.data['data_threshold'])
        setChatbotType(appState.chatbot.data['chatbot_type'])
    }

    useEffect(()=>{
        showEmbedders()
        if(appState.chatbot.value !== "None" && appState.chatbot.value!=='none'){
            requestChatbotData()
            
        }
        setEdit(false)
    },[appState.chatbot])

    return(


            <div hidden={!props.show}>            
            <h1 className="text-2xl m-5">Chatbot CORE Config</h1>
            <button className="Action-button w-36" disabled={appState.chatbot.value =="None"} onClick={()=>toggleEdit()}>Edit</button>
            <form className="GridContainer" onSubmit={formSubmit}>  


            

                <label className="grid-item1" id="name" >Chatbot Name</label>
                <input className="grid-item2" id="name-input" type="string" disabled={!edit} defaultValue={chatbotName} onChange={(e)=>setChatbotName(e.target.value)}></input>

                <label className="grid-item1" id="embeder">Embedder</label>
                <select className="grid-item2" id="embedder-select" disabled={!edit} value={chatbotType} onChange={(e)=>setChatbotType(e.target.value)}>
                    <option value={0} disabled>Select Type</option>
                    <option value={'Website'}>Website Assistant</option>
                    <option value={'Shop'}>Shop Assistant</option>
                    <option value={'Lawyer'}>Lawyer Assistant</option>
                </select>

                <label className="grid-item1" id="embeder">Embedder</label>
                <select className="grid-item2" id="embedder-select" disabled={!edit} value={embedder} onChange={(e)=>setEmbedder(e.target.value)}>
                    <option value={0} disabled>Select embedder</option>
                    {embeddersList}
                </select>

                <label className="grid-item1" id="prompt" >Prompt</label>
                <textarea className="grid-item2" id="prompt-input" type="string" disabled={!edit} value={prompt} onChange={(e)=>setPrompt(e.target.value)}></textarea>
                
                <label className="grid-item1" id="welcome" >Welcome message</label>
                <textarea className="grid-item2" id="welcome-input" type="string" disabled={!edit} placeholder="Welcome to our site, ask me anything about our company" value={welcomeMessage} onChange={(e)=>setWelcomeMsage(e.target.value)}></textarea>

                <label className="grid-item1" id="unknown_msg" >Unknown message</label>
                <textarea className="grid-item2" id="unknown-input" type="string"  disabled={!edit} placeholder="Im sorry, i don't have any knowledge on that topic" value={unknownMessage} onChange={(e)=>setUnknownMesage(e.target.value)}></textarea>

               
                <label className="grid-item1" id="data_thresh" >Data Similarity threshold</label>
                <input className="grid-item2" id="data_thresh-input" type="number" disabled={!edit}  min={0.01} max={1.0} step={0.01} placeholder={0.90} value={dataThreshold} onChange={(e)=>setDataThreshold(e.target.value)}></input>
    
                <button className="Action-button  bg-red-500 hidden"  id='cancel_btn' >Cancel</button>
                <button className="Action-button bg-green-500 hidden" id='save_btn' type='submit'>Save</button>
            </form>
</div>
    )

}

export default CoreConfig;