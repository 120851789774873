import { useState, useEffect } from 'react'
import { GetRequest } from '../../../functions/requestTemplates'
import GetProjects from '../../../functions/requstProjects'
import { useAppState } from '../../../hooks/AppState'
import Select from 'react-select'
const UserSelect = ()=>{
    const [projects, setProjects] = useState([])
    const [usersList, setUsersList] = useState([])
    const appState = useAppState()

    const setUser = (user) =>{
        console.log(user)
        appState.setAdminSelectedUser(user)
    }


    const defaultOptions = { value: 'selectuser', label: 'Select a User' }

    async function CreateUserDropdown(){
        let users = []
        const response = await GetRequest('admin/users/', '')
        const usersData = response['Users']
        for(const i in usersData){
            console.log(usersData[i])
            const element = {value: usersData[i]['username'], label: usersData[i]['username'], id:usersData[i]['id'], 
                            is_active : usersData[i]['is_active'], last_login: usersData[i]['last_login']}
            users.push(element)

                 
        }
        setUsersList(users)

    }

    useEffect(()=>{
        const userSelectElement = document.getElementById('userSelect')
        CreateUserDropdown()

    },[])

    

    return(
    <div>
        <Select
        id='userSelect'
        className="Sidebar-select-multi"
        classNamePrefix="select"
        defaultValue={defaultOptions}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        name="color"
        options={usersList}
        onChange={(e)=>setUser(e)}
      />

    </div>
    )
}

export default UserSelect;