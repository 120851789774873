import { useState } from "react"
import { useAuth } from "../../hooks/Authenticate"
import { Link } from "react-router-dom"
const LoginPage = () =>{
    const [loginInput, setLoginInput]= useState({username:'', password:''})


    const handleLoginInput = (e)=>{
        const name = e.target.name
        const value = e.target.value
        setLoginInput((prev)=>({...prev, 
            [name]: value
        }))
    }


    const auth = useAuth()
    async function submitLogin (e){
        e.preventDefault()
        if(loginInput.username !== '' && loginInput.password !== ''){

        auth.loginAction(loginInput)

        }else{
            alert('Please enter a username and password');
        }
        
}





    return(
        <div className="absolute top-[0] left-[0] w-full">
        <div className="flex w-full justify-center">
            <div className="Form-container">
                <div id='logo' className='Login-logo-container'>
                        <img className='Login-logo' src='/static/logo-ai-zuzi-ikona-1-100px.png' alt='logo'/>
                </div>
                <form className="flex flex-col" onSubmit={(e)=>{submitLogin(e)}}>
                    
                    <input className="Form-input" type="text" name='username' placeholder="Username" onChange={e =>handleLoginInput(e)}></input>
                    <input className="Form-input" type="password" name='password' placeholder="Password" onChange={e => handleLoginInput(e)}></input>
                    <button className="Form-button" type='submit'>Login</button>
                    
                    
                </form>

            </div>
        </div>
    
    </div>)
}

export default LoginPage;