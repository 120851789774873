import "../../CSS/Sidebar.css"
import {Link} from 'react-router-dom'
import { useAuth } from '../../hooks/Authenticate'
import AdminProjectSelect from "./components/adminProjectSelect"
import ProjectSelect from './components/ProjectSelect'
import ChatbotSelect from './components/ChatbotSelect'
import UserSelect from "./components/UserSelect"

const SidebarAdmin = (props) =>{
    const auth = useAuth()

    const logout = () =>{
        auth.logoutAction()
    }

    
    if(auth.user){

    return(
        <div className='Sidebar'>

            
            <div id='logo' className='Sidebar-logo-container'>
                <img className='Sidebar-logo' src='/static/logo-ai-zuzi-ikona-1-100px.png' alt='logo'/>
            </div>
            <h2 className='underline underline-offset-4 text-lg'>Selected User</h2>
            <UserSelect/>

            <Link to='/panel/create_user' onClick={()=>props.handleSelectedRoute('create-user')} >
                <button className='Sidebar-button' id='create-user'>Create User</button>
            </Link>

            <Link to='/panel/manage_user' onClick={()=>props.handleSelectedRoute('manage-user')} >
                <button className='Sidebar-button' id='manage-user'>Manage User</button>
            </Link>

            <h2 className='underline underline-offset-4 text-lg'>Selected Assistant</h2>
            <ChatbotSelect/>

            <Link to='/panel/create_chatbot' onClick={()=>props.handleSelectedRoute('create-chatbot')} >
                <button className='Sidebar-button' id='create-chatbot'>Create Chatbot</button>
            </Link>
            
            <h2 className='underline underline-offset-4 text-lg'>Selected Project</h2>

            <AdminProjectSelect/>

            <Link to='/panel/project_settings' onClick={()=>props.handleSelectedRoute('project-settings')}>
                <button className='Sidebar-button' id='project-settings'>Project Settings</button>
            </Link>

            <Link to='/panel/create_project' onClick={()=>props.handleSelectedRoute('create-project')} >
                <button className='Sidebar-button' id='create-project' >Create Project</button>
            </Link>

            
            <Link to='/panel/login'>
                <button className='Logout-button' onClick={()=>logout()}>Logout</button>
            </Link>
            
        </div>
    )}
}

export default SidebarAdmin;