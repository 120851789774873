import { useState, useEffect } from "react";
import NodeTable from "./nodeTable";
const Chunk =(props)=>{
    const [showMeta, setShowMeta] =useState(true)
    const [id, setId]=useState()
    const [text, setText] =useState()
    const [hasLeaf, setHasLeaf] = useState()
    const [version, setVersion] =useState()

    const searchSimilair = ()=>{
        props.searchChunks(text)
    }

    const handleShowMetadata=() =>{
        if(showMeta==true){
            
            setShowMeta(false)
        }else{
            setShowMeta(true)
        }
    }

    const createChunk=()=>{
        setId(props.data.id)
        setHasLeaf(props.data.link)
        setText(props.data.text)
        setVersion(props.data.judgment)
        console.log(props.data)
    }
    useEffect(()=>{
        createChunk()
    },[])
    
    

    return(
        <div className="bg-slate-300 mt-4 mb-4 ml-4">
            <h1>id: : {id} </h1>
                <div className="flex flex-row p-2">
                    <button className="Action-button bg-red-300 h-1/3">Remove</button>
                    <button className="Action-button bg-green-300 h-1/3">Edit</button>
                    <button className="Action-button bg-blue-300 h-1/3" onClick={()=>searchSimilair()}>Search Similar</button>
                    <textarea className=" resize-none w-2/3 ml-2 mr-2" disabled value={text}>
                    </textarea>
                    <div>
                        <button className="Action-button bg-blue-300 h-2/3" onClick={()=>handleShowMetadata()}>
                            Show metadata
                        </button>
                    </div>
                </div>
                <div hidden={showMeta}>
                    <div className="bg-slate-400 flex justify-center" >
                        <div className=" bg-slate-300 w-4/5 flex flex-col" >
                              <h1>Chunks meta data</h1>
                              <div className="flex m-4">
                                <label className="mr-20">Judgment :</label><h1 className="mr-20">{version}</h1>
                                <label className="mr-2">Link:</label><h1>{hasLeaf}</h1>
                              </div>
                              <div className="flex m-4 self-center flex-col">
  

                              </div>


                        </div>
                        <div>
                            
                        </div>
                    </div>

                </div>

                
        </div>
    )

}

export default Chunk;