import { useEffect, useState } from "react";
import { PostRequest } from "../../../functions/requestTemplates";
const CreateQuestion = (props) => {
    const [questionForm, setQuestionForm] = useState({question:'', answer:'', actions:'none', collection_id:props.collectionId})
    const [actionParam, setActionParam] = useState(null)
    const handleFormChange = (e)=>{
        setQuestionForm((prevState)=>{
            return {...prevState, [e.target.id]:e.target.value}
        })
    }
    const handleSubmit = async(e)=>{
        e.preventDefault()
        await PostRequest('question_answer/', questionForm)
        props.refresh()
    }
    return(
        <div>
            <h1>New Qustion</h1>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <div className="mt-2">
                   <label>Question</label><input className="rounded-md ml-2 w-2/5" type='text' id='question' value={questionForm.question} onChange={(e)=>handleFormChange(e)}></input>
                </div>
                <div className="mt-2">
                   <label>Answer</label><input className="rounded-md ml-5 w-2/5" type='text' id='answer' value={questionForm.answer} onChange={(e)=>handleFormChange(e)}></input>
                </div>
                <div className="mt-2">
                     <label>Action</label><select className="rounded-md ml-5 w-1/5">
                        <option value='none'>Select...</option>
                     </select>
                     <label className="ml-2">Parameters</label><input className="rounded-md ml-5 w-1/5"></input>
                </div>
                <input className="Action-button bg-blue-400 mt-5" type='submit' value={'Add Question'}></input>
            </form>
        </div>
    )
}

export default CreateQuestion;