import '../../CSS/Topbar.css'
import {Link} from 'react-router-dom'



const AdminTopBar = (props) =>{
    return(
        <div className='TopBar'>
            
            <Link to='/panel/chatbot_core' onClick={(e)=>props.handleSelectedRoute('chatbot-config')}>
                <button className='Topbar-button' id='chatbot-config'>Chatbot Config</button>
            </Link>
                                
                                
            <Link to='/panel/chatbot_cron' onClick={(e)=>props.handleSelectedRoute('tasks')}>
                <button className='Topbar-button' id='tasks'>Tasks</button>
            </Link>
                                
            <Link to='/panel/chatbot_database' onClick={(e)=>props.handleSelectedRoute('database')}>
                <button className='Topbar-button' id='database'>Database</button>
            </Link>

            <Link to='/panel/chunks' onClick={(e)=>props.handleSelectedRoute('chunks')}>
                <button className='Topbar-button' id='chunks'>Chunks</button>
            </Link>

            <Link to='/panel/chatbot_functions' onClick={(e)=>props.handleSelectedRoute('functions')}>
                <button className='Topbar-button' id='functions'>Functions</button>
            </Link>                                   
                                
            
            <Link to='/panel/testChat' onClick={(e)=>props.handleSelectedRoute('test-chat')}>
                <button className='Topbar-button Selected' id='test-chat'>AI Cases</button>
            </Link>


            <Link to='/panel/conversations' onClick={(e)=>props.handleSelectedRoute('conversation')}>
                <button className='Topbar-button' id='conversation'>Conversations</button>
            </Link>

            
            <Link to='/panel/QandA' onClick={(e)=>props.handleSelectedRoute('QandA')}>
                <button className='Topbar-button' id='QandA'>Q & A</button>
            </Link>

            
        </div>
    )
}

export default AdminTopBar;