import { useContext, createContext, useState, useEffect } from "react";
import { UpdateSession } from "../functions/sessionApi";
import { GetRequest } from "../functions/requestTemplates";
import { createChatbotDropdown } from "../functions/createProps";
import { GetSession } from "../functions/sessionApi";
const AppStateContext = createContext()

const AppStateProvider = ({children}) =>{
    const [logged, setLogged] = useState(false)
    const [project, setProject] = useState({'name':'None', 'value': 'None'})
    const [chatbot, setChatbot] = useState({'name':'None', 'value': 'None'})
    const [adminUser, setAdminUser] = useState(false)
    const [adminSelectedUser, setAdminSelectedUser] = useState('None')
    const [chatbotDropdown, setChatbotDropdown] = useState([])



    const updateUserSession=(project, chatbot)=>{
        const data = {'active_project_name' : project.name, 'active_project_id': project.value,
                      'active_chatbot_name' : chatbot.name, 'active_chatbot_id': chatbot.value}
        if(!adminUser){
          UpdateSession(data)
        }
        
      }
      
    const chatbotHandleChange = async(chatbot)=>{
        console.log(chatbot)
        let data = null
        if(chatbot.value !== 'None'){
          data = await GetRequest('chatbot_manage/?chatbot_id=', chatbot.value)
        }else{
          data = {'data':{}}
        }
        
        setChatbot({'name' : chatbot.name, 'value' : chatbot.value, 'data' : data['data']})
        updateUserSession(project, chatbot)
      }
    
    const projectHandleChange = (project)=>{
        const chatbot = {'name':'None', 'value': 'None'}
        setProject(project)
        setChatbot(chatbot)
        if(!adminUser){

          updateUserSession(project, chatbot)
        
        }

      }
    
    const createProps = async ()=>{
        if (project !== undefined && project.value !== 'None'){
        setChatbotDropdown(await createChatbotDropdown(project))
      }else{
        setChatbotDropdown([])
      }}
    
    async function processSessionData(){
          setChatbot({'name' : "none", 'value' : "none"})
          let chatbot = null
          const data = await GetSession()
    
          if(data['active_chatbot_id'] !== 'None'){
            chatbot = await GetRequest('chatbot_manage/?chatbot_id=', data['active_chatbot_id'])
          }else{
            chatbot = {'data':{}}
          }
    
          const project_data = {'name' : data['active_project_name'], 'value' : data['active_project_id']}
          const chatbot_data = {'name' : data['active_chatbot_name'], 'value' : data['active_chatbot_id'], 'data' : chatbot['data']}
    
          setProject(project_data)
          setChatbot(chatbot_data)
    
      }
    
      
    async function asyncProccessSession(){
        if(project.value == 'None' && !adminUser){

            await processSessionData()
        }
      }  
 
      
    useEffect(()=>{
      if(logged){
        asyncProccessSession()
      }
      
    },[project, logged])


    useEffect(()=>{
      if(logged){
        console.log(project)
        createProps()
      }
      },[project])

    return(
    <AppStateContext.Provider value={{project, chatbot, chatbotDropdown, adminSelectedUser, 
    setProject : projectHandleChange, setChatbot : chatbotHandleChange, getSession : processSessionData, updateSession : updateUserSession, setLogged, setAdminUser, setAdminSelectedUser}}>
        {children}
    </AppStateContext.Provider>
    )
}

export default AppStateProvider;

export const useAppState = () =>{
    return useContext(AppStateContext);
};