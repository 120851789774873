import { useState, useEffect } from "react"
import { GetRequest } from "../../../functions/requestTemplates"

const Sidebar = (props) => {
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [collectionData, setCollectionData] = useState(null)
    const [awaiting, setAwaiting] = useState(false)
    const handleChange = async (e)=>{
        console.log(e.target.value)
        if(selectedCollection !== e.target.value){
            setSelectedCollection(e)
            props.setSelectedView('View')
            props.setCollection({'id':null, 'name':null, 'data':null})
            setAwaiting(true)
            const collectionData = await GetRequest('question_answer/?collection_id=', e.target.value)

            props.setCollection({'id':e.target.value, 'name':e.target.name, 'data':collectionData})
            setAwaiting(false)
            
        }
    }

    const createStaticButtons = (array)=>{
        console.log(array)
        if (array!==null){

            const ActiveCollection = array.filter((data)=>{return data.name==='Active'})[0]
            const CollectCollection = array.filter((data)=>{return data.name=='Collect'})[0]

            console.log(ActiveCollection)

            const activeButton = <button className="Action-button bg-blue-400 m-2" value={ActiveCollection.pk} onClick={(e)=>console.log(e.target.value)}>Active Q&A</button>
            const collectButton = <button className="Action-button bg-blue-400 m-2" value={CollectCollection.pk} onClick={(e)=>handleChange(e)}>Asked Questions</button>
            array.splice(array.indexOf(ActiveCollection),1)
            array.splice(array.indexOf(CollectCollection),1)
            return <>{activeButton} {collectButton}</>

    }}

    const createDynamicButtons = (array)=>{
        if (array!==null){
            if(array.length>0){

                return array.map((data)=>{
                    return <button className="Action-button bg-blue-400 m-2" disabled={awaiting} value={data.pk} name={data.name} onClick={(e)=>handleChange(e)}>{data.name}</button>
                })
            
            }
        }
        
    
    }


    useEffect(()=>{
        setCollectionData(props.collectionList)
    },[props.collectionList])

    return(

            <div className="flex flex-col w-[12%] h-full overflow-y-auto scroll-smooth [scrollbar-width:thin] bg-slate-500">                
                <h1> Collections </h1>
                <button className="Action-button bg-green-400 m-2" onClick={()=>props.setSelectedView('Create')}>Create</button>
                {createDynamicButtons(collectionData)}
                

            </div>
        
 

    )
}

export default Sidebar;