import { useState, useEffect} from "react"
import { useNavigate } from "react-router-dom"
import { PostRequest } from "../../functions/requestTemplates"
import {createChatbot, createScrapper, createCache} from "./api/createChatbotApi"
import { getEmbedders } from "./api/createChatbotApi"
import CreateCore from "./components/createCore"
import CreateParser from "./components/createParser"
import { useAppState } from "../../hooks/AppState"
const CreateChatbotPage = ()=>{
    const [page, setPage]=useState('core')
    const[embedders, setEmbedders] = useState()
    const [coreSettings, setCoreSettings] = useState()
    const [parserSettings, setParserSettings] = useState()
    const [submit, setSubmit] = useState()
    const [loader, setLoader] = useState(false)

    const appState = useAppState()
    const navigate = useNavigate()

    const formValid = () =>{
        return true
    }

    async function showEmbedders(){       
        let embedder_list = []
        const embedder = await getEmbedders(setLoader)

        for(const i in embedder){
            const array = embedder[i]
            const element = <option key={i} name={array[0]} value={array[1]}>{array[0]}</option> 
            embedder_list.push(element)
                 
        }
        setEmbedders(embedder_list)}


    async function requestCreate (){
        if (formValid()){
            const cacheData = {
                'save_to_cache':false,
                'read_from_cache':false,
                'cache_threshold':0.95
            }
            const chatbot_data = Object.assign({}, coreSettings, parserSettings, cacheData)
            console.log(chatbot_data)
            const new_chatbot = await PostRequest('chatbot_manage/', chatbot_data)
            console.log(new_chatbot)
            

            //parserSettings.chatbot_id = chatbot_id
            //const parser = await createScrapper(parserSettings)



            //const cache = await createCache(cacheData)

            const chatbot_name = coreSettings['chatbot_name']
            appState.setChatbot({'name' : chatbot_name, 'value': new_chatbot.chatbot_id, 'data':chatbot_data})
            navigate('/panel/')
                
        }
    }


    useEffect(()=>{
        showEmbedders()
    },[])

    useEffect(()=>{
        if(submit==true){
            requestCreate()
        }
    },[submit])

    return(
        <div className="PageContainer">
            <h1>Create New Chatbot</h1>
            <div className="w-1/2 self-center bg-slate-300">

            <CreateCore embedders={embedders} hide={page!=='core'} setSettings={setCoreSettings} setPage={setPage}/>
            <CreateParser  hide={page!=='parser'} setSettings={setParserSettings}  setPage={setPage} setSubmit={setSubmit}/>
            </div>
        </div>
    )
}

export default CreateChatbotPage;