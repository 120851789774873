import React from 'react';
import { useState, useEffect } from 'react';
const InfoSourceTable = (props) => {
    const [nodes, setNodes] = useState(null)
    const [headers, setHeaders] = useState()
    const [rows, setRows] = useState()
    const [selectedRows, setSelectedRows] = useState([])
    const [activeRows, setActiveRows] = useState([])
 

    const handleSelect = (e)=>{
        const checked = (e.target.checked)
        const value = parseInt(e.target.value)
        if(checked){
            const selected = selectedRows
            selected.push(value)
            setSelectedRows(selected)
        }else{
            const selected = selectedRows
            const index = selected.indexOf(value)
            selected.splice(index, 1)
            setSelectedRows(selected)   
        }

    
    }

    const handleActive = (e)=>{
        const checked = (e.target.checked)
        const value = parseInt(e.target.value) // required so the value is an int and not a string
        if(checked){
            const active = activeRows
            active.push(value)
            setActiveRows(active)
        }else{
            const active = activeRows
            const index = active.indexOf(value)
            active.splice(index, 1)
            setActiveRows(active)   
        }
        props.setActiveList(activeRows)
    }
  

    const create_headers =()=>{
        const head = nodes[0]
        const keys = Object.keys(head)
        //add select column
        keys.push('select')


        const index = keys.indexOf('chatbot')
        keys.splice(index, 1)
        const heads = keys.map((key)=>{
            return <th>{key}</th>
        })
        setHeaders(heads)
        create_rows()


    }

    const create_rows=()=>{
        const rows = nodes.map((infosource)=>{
            const row_id = infosource['id']
            const keys = Object.keys(infosource)

            //create select element and add it to the keys array
            const select_element = <input type='checkbox' key={row_id} value={row_id} onClick={(e)=>handleSelect(e)}/>
            keys.push('select')
            infosource['select'] = select_element

            //remove chatbot key/value pair
            const index = keys.indexOf('chatbot')
            keys.splice(index, 1)
            const id = infosource['id']
            const row = keys.map((key)=>{
                if(key=='active'){
                    if(infosource[key]==true){
                        const active = activeRows
                        active.push(id)
                        setActiveRows(active)
                    }
                    return <td><input type='checkbox' onChange={(e)=>handleActive(e)} value={id} id={id} defaultChecked={infosource[key]}/></td>
                }
                if(key=='updated'){
                    return <td><input type='checkbox' disabled id={id} checked={infosource[key]}/></td>
                }
                return <td>{infosource[key]}</td>
            })
            return <tr>{row}</tr>
        })
        setRows(rows)
        props.setActiveList(activeRows)
    }

    useEffect(()=>{
        if(nodes!=null){
            create_headers()
        }
        
    },[nodes])

    useEffect(()=>{
        setNodes('')
        setSelectedRows([])
        setActiveRows([])
        setHeaders([])
        setRows([])
        setNodes(props.infoSources)
    },[props.infoSources])


    return (
        <div className='flex justify-center self-center w-[100%] p-10'>
            <table className='bg-gray-200 w-[100%]'>
                <thead>
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
};

export default InfoSourceTable;
