import { useState, useEffect } from "react";
import ManagePage from "./manage_tasks/managePage";
import { useAppState } from "../../hooks/AppState";
import Loader from "../../components/other/Loader";
const TasksPage = ()=>{
    const [selectedPage, setSelectedPage] = useState('manage')
    const appState = useAppState()
    if(appState.chatbot.data !== undefined){
    return(
        <div className="PageContainer bg-slate-300">

        <div className='flex flex-row bg-slate-400'>
                <button className='Action-button-selected' value='manage' onClick={(e)=>setSelectedPage(e.target.value)}>Manage Tasks</button>
        </div>
            <h1>Chatbot Tasks</h1>
            <h2>Selected chatbot : {appState.chatbot.name}</h2>
            <ManagePage hidden ={selectedPage!='manage'}/>



        </div>
    )}else{
        return(

            <Loader/>

        )
    }
}

export default TasksPage;