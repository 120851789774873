import { useState, useEffect } from "react";
import { addTask } from "../../chatbot_tasks/tasksApi";
import Loader from "../../../components/other/Loader";
import { useAppState } from "../../../hooks/AppState";
const EmbeddingComponenet = ()=>{
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [consoleMessage, setConsoleMessage] = useState('')
    const appState = useAppState()
    const EmbeddingTask = async ()=>{
        const task ={
            'chatbot_id' : appState.chatbot.value,
            'type' : 2,
            'parameters' : 'test',
            'state':0,
            'command':0,
        }
        setConsoleMessage('Task added to queue')
        await addTask(task)
    }


    const handleEdit=()=>{
        if(edit){
            setEdit(false)
        }else{
            setEdit(true)
        }
    }
    return(
        <div className="bg-slate-200 m-4 w-1/2 flex flex-col">
            <h1>Create embddings</h1>
            <div className="bg-slate-100 w-4/5 flex flex-col self-center min-h-[200px]">
                <h2 className="">Choose Files</h2>
                <div className=" bg-white flex flex-col overflow-y-scroll min-h-[200px] max-h-[200px]">
        
                <label>File1.pdf<input className="m-2" type='checkbox'/></label>

                

                </div>
                
            </div>

            <div className="bg-slate-100 w-4/5 flex flex-col self-center min-h-[200px] mt-10">

                <h2>Console</h2>
                <textarea disabled={true} className=" h-[100%]" value={consoleMessage}>{consoleMessage}</textarea>

                
                
            </div>
            
            

        {
            loading
            ?   <div>
                    <Loader />
                </div>
            :<div>
            <button className="Action-button"onClick={()=>EmbeddingTask()} >Create Database</button>
            <button className="Action-button"onClick={()=>handleEdit()} >Copy Database</button>
            <button className="Action-button"onClick={()=>handleEdit()} >Upload Files</button>

        </div>
        }
        
        
        </div>
    )
}

export default EmbeddingComponenet;