import React from 'react';
import { useState, useEffect } from 'react';
import { updateTasks } from '../../tasksApi';

const TaskTable = (props) => {
    const [tasks, setTasks] = useState(null)
    const [headers, setHeaders] = useState()
    const [keys, setKeys] = useState()
    const [rows, setRows] = useState()

    const options_buttons=(id)=>{
        return <div className='flex justify-evenly max-w-xs'>
            <button className='m-2 bg-red-200 p-1 rounded-md pr-2 pl-2' value={id} onClick={(e)=>handleCancel(e)}>Remove</button>    
            </div>
    }

    const handleCancel = (e)=>{
        const task = {
            'id':e.target.value,
            'command':1,

        }
        updateTasks(task)
    }

    const process_type=(type)=>{
        // change the type number id to string
        if(type==1){
            return 'Creating Info sources'
        }
        if(type==2){
            return 'Creating Database'
        }
        if(type==3){
            return 'Parser'
        }
        if(type==101){
            return 'Test Task'
        }
    }

    const process_state=(state)=>{
        // change the state number id to string
        if(state==0){
            return 'Queued'
        }
        if(state==1){
            return 'Running'
        }
        if(state==2){
            return 'Finished'
        }
        if(state==3){
            return 'Error'
        }
    }

    const process_command=(command)=>{
        // change the command number id to string
        if(command==0){
            return 'Scheduled to run'
        }
        if(command==1){
            return 'Cancelling'
        }
    }

    const process_array =(array)=>{
        // remove chatbot and id keys 
        const header = array
        const index = header.indexOf('chatbot')
        header.splice(index, 1)
        const index2 = header.indexOf('id')
        header.splice(index2, 1)
        // and add options key for displaying buttons in table
        array.push('options')
        return array
    }

    const create_headers =()=>{
        const head = tasks[0]
        const keys = process_array(Object.keys(head))
        const heads = keys.map((key)=>{
            return <th>{key}</th>
        })
        setHeaders(heads)

        create_rows()
        
    }
    const create_empty_table=()=>{
        const task = {
            'id':0,
            'type':'',
            'parameters':'',
            'state':'',
            'command':'',
            'options':'',
        }
        const keys = process_array(Object.keys(task))
        const heads = keys.map((key)=>{
            return <th>{key}</th>
        })
        setRows()
        setHeaders(heads)
        
    }

    const create_rows=()=>{
        const rows = tasks.map((task)=>{
            const id = task['id']
            const keys = Object.keys(task)
            const keys_processed = process_array(keys)
            const row = keys_processed.map((key)=>{
                if(key=='type'){
                    return <td>{process_type(task[key])}</td>
                }
                if(key=='state'){
                    return <td>{process_state(task[key])}</td>
                }
                if(key=='command'){
                    return <td>{process_command(task[key])}</td>
                }
                if(key=='options'){
                    return <td className='max-w-xs'>{options_buttons(id)}</td>
                }
                return <td>{task[key]}</td>
            })
            return <tr>{row}</tr>
        })
        setRows(rows)
    }

    useEffect(()=>{
        if(tasks!=null){
            create_headers()
        }else{
            create_empty_table() 
        }
        
    },[tasks])

    useEffect(()=>{
        setTasks(props.tasks)

    },[props.tasks])


    return (
        <div className='flex justify-center self-center w-[100%] p-10'> 
            <table className='bg-gray-200 w-[100%]'>
                <thead>
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
};

export default TaskTable;
