import "../../CSS/Sidebar.css"
import {Link} from 'react-router-dom'
import { useAuth } from '../../hooks/Authenticate'
import { useAppState } from '../../hooks/AppState'
import ProjectSelect from './components/ProjectSelect'
import ChatbotSelect from './components/ChatbotSelect'
import SidebarAdmin from "./SidebarAdmin"
import SidebarUser from "./SidebarUser"
import { useEffect } from 'react'

const Sidebar = (props) =>{
    const auth = useAuth()
    const handleSelectedRoute = (value)=>{
        const previousElement = document.getElementById(props.selectedRoute)
        if (previousElement){
            previousElement.classList.remove('Selected')

        }
        props.setSelectedRoute(value)
        const newElement = document.getElementById(value)
        newElement.classList.add('Selected')
    }


    
    if(auth.user){

    return(
        auth.userType=='Admin'? <SidebarAdmin handleSelectedRoute={handleSelectedRoute}/>:<SidebarUser handleSelectedRoute={handleSelectedRoute}/>
    )}
}

export default Sidebar;