import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../components/other/Urls";
const api_url = Api_url()

async function GetRequest(url, urlParams){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+url+urlParams,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Authorization': token,
        },
       })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}


async function PostRequest(url, data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+url,{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':data

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

async function PatchRequest(url, data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+url,{
        method:'PATCH',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':data

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

async function DeleteRequest(url, data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+url,{
        method:'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':data

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

export {GetRequest, PatchRequest, PostRequest, DeleteRequest};