import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { PaperPlaneIcon } from "@radix-ui/react-icons";
import { Input } from "@/src/components/ui/input";
import {
    Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, } from "@/src/components/ui/select"
import {
        Tooltip,
        TooltipContent,
        TooltipProvider,
        TooltipTrigger,
      } from "@/src/components/ui/tooltip"


const ChatInput = (props) => {
    const [input, setInput] = useState('')
    const [responseMode, setResponseMode] = useState('optimal')
    const [responseLength, setResponseLength] = useState('short')
    
    const handleSend = (e)=>{
        e.preventDefault()
        props.addMessageUser(input, responseMode, responseLength)
        setInput('')
    }

    return(
    <div className="flex flex-col mt-auto mb-1 border-t w-full h-fit">
    <div className="flex flex-row">
    <TooltipProvider>
        <Select className='mt-2 mb-2 w-full' onValueChange={(value)=>setResponseMode(value)}>
                    <Tooltip>
                        <TooltipTrigger asChild>
                        <SelectTrigger className="mt-2 mb-2 w-1/3">
                            <SelectValue placeholder="Optimal" />
                        </SelectTrigger>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Choose the type of response</p>
                        </TooltipContent>
                    </Tooltip>          

            <SelectContent
            className='w-[500px]'
            
            >
                <SelectGroup>
                    <SelectLabel>Respose Mode</SelectLabel>

                        <Tooltip>
                        <TooltipTrigger asChild>
                            <SelectItem value={'optimal'}>Optimal</SelectItem>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Get a single response for the most optimal matching source</p>
                        </TooltipContent>
                        </Tooltip>


                        <Tooltip>
                        <TooltipTrigger asChild>
                            <SelectItem value={'5best'}>5 Best</SelectItem>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Get a response for up to 5 of the best matching sources</p>
                        </TooltipContent>
                        </Tooltip>


                        <Tooltip>
                        <TooltipTrigger asChild>
                            <SelectItem value={'all'}>All
                            </SelectItem>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Get a response for all selected sources</p>
                        </TooltipContent>
                        </Tooltip>

                </SelectGroup>
            </SelectContent>
        </Select>
    </TooltipProvider>

    <Select onValueChange={(value)=>setResponseLength(value)}>
        <TooltipProvider>
        <Tooltip>
        <TooltipTrigger asChild>
        <SelectTrigger className="ml-2 mt-2 mb-2 w-1/3" >
            <SelectValue placeholder="Short" />
        
        </SelectTrigger>
        </TooltipTrigger> 
        <TooltipContent>
            <p>Choose the length of the response</p>
        </TooltipContent>
        </Tooltip>
        </TooltipProvider>
        <SelectContent>
            <SelectGroup>
                <SelectLabel>Respose Length</SelectLabel>
                <SelectItem value={'short'}>Short</SelectItem>
                <SelectItem value={'medium'}>Medium</SelectItem>
                <SelectItem value={'long'}>Long</SelectItem>
            </SelectGroup>
        </SelectContent>
    </Select>
    
    
    </div>   
        <form className="flex w-full" onSubmit={(e)=>handleSend(e)}>
 
    
        <Input
            type="text"
            name='message'
            placeholder="Chat..."
            value={input}
            onChange={(e)=>setInput(e.target.value)}
            
            className="p-1 max-w-full border-blue-200 mr-2"
        />
            <button type='submit' className=" bg-blue-300 rounded-sm"><PaperPlaneIcon className="w-10"/>
            </button>


     

        </form>

        

    </div>)
}

export default ChatInput;