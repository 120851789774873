import './Loader.css'

const Loader = () =>{
    return(
        <div className="PageContainer flex">
        <div className="self-center mt-[20%]">
            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>)

        </div>
        
    </div>)
        }


export default Loader;