import '../../CSS/Topbar.css'
import {Link} from 'react-router-dom'
import ChatbotSelect from '../sidebar/components/ChatbotSelect'
import { useState } from 'react'
import { useAuth } from '../../hooks/Authenticate'
import { useAppState } from '../../hooks/AppState'
import UserTopBar from './TopbarUser'
import AdminTopBar from './TopbarAdmin'

const TopBar = (props) =>{
    const auth = useAuth()

    const handleSelectedRoute = (value)=>{
        const previousElement = document.getElementById(props.selectedRoute)
        if (previousElement){
            previousElement.classList.remove('Selected')

        }
        props.setSelectedRoute(value)
        const newElement = document.getElementById(value)
        newElement.classList.add('Selected')
    }


    if(auth.user){
    return(
        auth.userType=='Admin'?<AdminTopBar handleSelectedRoute={handleSelectedRoute}/>:<UserTopBar handleSelectedRoute={handleSelectedRoute}/>
    )}
}

export default TopBar;