import * as React from "react"
import { ArrowUpDown, MoreHorizontal } from "lucide-react"
import { Button } from "@/src/components/ui/button"
import { Checkbox } from "@/src/components/ui/checkbox"
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger} from "@/src/components/ui/dropdown-menu"

export const columns = [

    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllRowsSelected() ||
            (table.getIsSomeRowsSelected() && "indeterminate")
          }
          onCheckedChange={value => table.toggleAllRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          className="mr-2"
          checked={row.getIsSelected()}
          onCheckedChange={value => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false
    },

    {
      accessorKey: "title",
      header: "Case No.",
      cell: ({ row }) => (
        <div className="capitalize text-left">

            {row.getValue("title")}
    
      </div>
      )
    },

    {
      accessorKey: "judgment",
      header: "Judgment",
      cell: ({ row }) => (
        <div className="capitalize text-left">{row.getValue("judgment")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },

    {
      accessorKey: "jurisdiction",
      header: "Jurisdiction",
      cell: ({ row }) => (
        <div className="flex space-x-1">
        <span className="">
        {row.getValue("jurisdiction")}
        </span>
      </div>
      )
    },

    {
      accessorKey: "judgment_text",
      header: "Judgment Text",
      cell: ({ row }) => 
      <div className="flex space-x-1">
      <span className="">
      {row.getValue("judgment_text")}
      </span>
    </div>

    },   

    {
      accessorKey: "country",
      header: "Country",
      cell: ({ row }) => (
        <div className="capitalize text-left">{row.getValue("country")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },

    {
      accessorKey: "score",
      header: ({column}) => (
        <div className=" max-w-[68px]">
        <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Similarity
        
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button></div>
      
      ),
      cell: ({ row }) => {
        const score = row.getValue("score")
  
  
        return <div className="text-center font-medium">{score}%</div>
      }
    },

    {
      accessorKey: "decision_date",
      header: ({column}) => (

        <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
      
      ),
      cell: ({ row }) => {
        const date = row.getValue("decision_date")
  
  
        return <div className="text-left font-medium">{date}</div>
      }
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const case_row = row.original
  
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => navigator.clipboard.writeText(case_row.url)}
              >
                Copy link
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => window.open(case_row.url, "_blank").focus()}
              >
                Open Case in new tab
              </DropdownMenuItem>


            </DropdownMenuContent>
          </DropdownMenu>
        )
      }
    }
  ]