import { useEffect, useState } from "react"
import GetChatbots from "../../../functions/requestChatbots"
import CopyChatbot from "./overlayCopyWindow"
import ChatbotDelete from "./overlayDeleteChatbot"
import { useAppState } from "../../../hooks/AppState"
const ChatbotManage=()=>{
    const [chatbots, setChatbots] = useState()
    const[showCopy, setShowCopy] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selctedChatbot, setSelectedChatbot] =useState()
    const [selectedChatbotName, setSelectedChatbotName]=useState()
    const appState = useAppState()
    async function handleCreateCopy(e){
        setSelectedChatbot(e.target.value)
        setShowCopy(true)

    }

    async function handleDelete(e){
        setSelectedChatbot(e.target.value)
        const chatbot_name = e.target.id
        setSelectedChatbotName(chatbot_name)
        setShowDelete(true)
    }


    async function showChatbots(){ 
        if (appState.project.value !== 'None' && appState.project.value !== 'none'){
        let chatbot_list = []
        const chatbots_list = await GetChatbots(appState.project.value)

        for(const i in chatbots_list){
            const array = chatbots_list[i]
            const element = <div className=" mt-4 mb-4 flex flex-row text-center">
                <div className="w-96">
                    <label className="" key={i} name={array[0]} value={array[1]}>{array[0]}</label> 
                </div>
                <div className="w-96">
                    
                    <button className="Action-button bg-green-400" id={array[0]} value={array[1]} onClick={(e)=>{handleCreateCopy(e)}}>Copy Config</button>
                    <button  className="Action-button bg-red-400" id={array[0]} value={array[1]} onClick={(e)=>{handleDelete(e)}}>Delete</button>
                </div>

                            </div>

                            
            chatbot_list.push(element)
        }
        setChatbots(chatbot_list)

    
    }
    }

    useEffect(()=>{
        showChatbots()
    },[appState.project.value, appState.chatbot.value])
    return (
        <div className="bg-slate-300 mt-6" key={appState.chatbot.value}>
            <h1 className="mb-4 underline">Project chatbots</h1>
            <div className=" overflow-y-scroll max-h-96 bg-slate-200 m-4">
                {chatbots}
            </div>
            
            <CopyChatbot hide={!showCopy} chatbot={selctedChatbot} setShow={setShowCopy}/>
            <ChatbotDelete hide={!showDelete} chatbot={selctedChatbot} chatbot_name={selectedChatbotName} setShow={setShowDelete}/>
        </div>
    )
}
export default ChatbotManage;