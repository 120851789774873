import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../components/other/Urls";
const api_url = Api_url()


async function CreateCopy(chatbot_id, copy_name, project_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chatbot_copy',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'chatbot_id':chatbot_id,
            'copy_name':copy_name,
            'project_id':project_id

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}

async function CopySettings(data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chatbot_copy',{
        method:'PATCH',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data': data

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}

async function DeleteChatbot(chatbot_id, project_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chatbot_manage/',{
        method:'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'chatbot_id':chatbot_id,
            'project_id':project_id


        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}
export {CreateCopy, CopySettings, DeleteChatbot};