import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../components/other/Urls";
const api_url = Api_url()

async function GetSession(){
    const token = String(Cookies.get('token'))

    return (await fetch(api_url+'cms_session/',{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            }))}

async function UpdateSession(data){
    const token = String(Cookies.get('token'))
    return (await fetch(api_url+'cms_session/',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data' : data

        })
        ,})
        .then((response)=>{
            return response.json()
        })
       )}


export {GetSession, UpdateSession};




