import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../../components/other/Urls";

async function getChatbotData(chatbot_id){
    const api_url = Api_url()
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chatbot_manage/?chatbot_id='+chatbot_id,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })}


async function updateChatbotData(data){
    const api_url = Api_url()
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chatbot_manage/',{
        method:'PATCH',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':data,

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.data

            })
    
}
export {updateChatbotData};