import CacheTable from "./components/table";
import './components/table.css'
import { useState, useEffect } from "react";
import CachePreview from "./components/CacheTablePreview";
import handleActiveButton from "../../functions/handleActiveButton";
import { useAppState } from "../../hooks/AppState";
const CachePage = (props) =>{
    const[page, setPage] = useState('cache-preview')
    const appState = useAppState()
    const handlePage=(newpage)=>{
        setPage(newpage)
        handleActiveButton(newpage, page)
    }


    
    return(
        <div id ='CachePage'className="PageContainer">

            <div className='flex flex-row bg-slate-400'>
                <button className="Action-button-selected" id='cache-preview' value='cache-preview' onClick={(e)=>handlePage(e.target.value)}>Preview</button>
                </div>

            <h1>Here you can manage your cache!</h1>
            <h2>Selected chatbot : {appState.chatbot.name}</h2>

                <CachePreview show={page=='cache-preview'} chatbot={appState.chatbot}/>
                
         
        </div>
    )
}

export default CachePage;