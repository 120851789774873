import CopyToExistingComponent from "./copy_components/copyToExistingComponent";
import CopyToNewComponent from "./copy_components/copyToNewComponent";
import { useEffect, useState } from "react";
import { useAppState } from "../../../hooks/AppState";
const CopyChatbot = (props)=>{
    const [selectedPage, setSelectedPage]=useState('toNew')
    const appState = useAppState()
    const handlePage=()=>{

    }
    
    return(
    
    <div hidden={props.hide} className=" w-[100%] h-[100%] fixed left-0 top-0 bg-gray-600 bg-opacity-50 ml-[210px]">
    <div className="flex mr-52 justify-center content-center mt-[120px]">
        <div className="bg-slate-200  border-2 border-slate-950 w-1/3 h-1/3 self-center flex flex-col">
            <div>
                 
            </div>
            <div className='flex flex-row bg-slate-200'>
                <button className='Action-button' value='toNew' onClick={(e)=>setSelectedPage(e.target.value)}>Copy to new</button>
                <button className="Action-button" value='toExisting' onClick={(e)=>setSelectedPage(e.target.value)}>Copy to existing</button>
                
                </div>
                    <CopyToNewComponent hide={selectedPage!=='toNew'} chatbot={props.chatbot} setShow={props.setShow}/>
                    <CopyToExistingComponent hide={selectedPage!=='toExisting'} setShow={props.setShow} />
                    



        </div>

    </div>

</div>)
}

export default CopyChatbot;