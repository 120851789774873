import { useState, useEffect } from "react";
import { CreateCopy } from "../../Api";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../../../hooks/AppState";
const CopyToNewComponent = (props)=>{
    const [chatbotName, setChatbotName] = useState()
    const[copyConfig, setCopyConfig]=useState()
    const[copyVdb, setCopyVdb]=useState()
    const[copyCache, setCopyCache]=useState()
    const[copyParser, setCopyParser]=useState()
    const[copyTasks, setCopyTasks]=useState()
    const appState = useAppState()

    const navigate= useNavigate()
    async function createCopy(){
        const config = {
            'copyConfig' : copyConfig,
            'copyCache': copyCache,
            'copyParser': copyParser,
        }

        const new_chatbot = await CreateCopy(props.chatbot, chatbotName, appState.project.value)
        appState.setChatbot({'name':chatbotName, 'value':new_chatbot})
        navigate('/panel/chatbot_core/')

    }
    return(
       <div hidden={props.hide}>
        <button>Create a new chatbot from an existing one</button>   

        <div className="m-4">
        <label>Copy name</label>
        <input value={chatbotName} onChange={(e)=>setChatbotName(e.target.value)}></input>
        </div>



            <div className="m-4">
                <label>Copy cache</label>
                <input className='m-2'type='checkbox' onChange={(e)=>setCopyCache(e.target.checked)}></input>
            </div>



        <div>
            <button className="Action-button bg-red-400" onClick={()=>props.setShow(false)}>Cancel</button>
            <button className="Action-button bg-green-400" onClick={()=>createCopy()}>Create Copy</button>

        </div>
        </div>
    )
}

export default CopyToNewComponent;