import { useEffect, useState } from "react";
const ConversationDisplay = (props)=>{
    const [elements, setElements] = useState()
    const [dateCreated, setDateCreated] = useState()
    const [conversationId, setConversationId] = useState()
    const [chatbot_name, setChatbot_name] = useState()

    const createChatMessages = ()=>{
        const chatMessages = props.conversation.messages.map((message, index)=>{
            console.log(message)
            if(message.author=='Chatbot' && message.source){
                return(
                    <div className="m-1 self-start max-w-[90%] flex p-2 text-left">
                        <div className="resize-none rounded-md bg-blue-300 p-2 w-[100%]" disabled>
                            {message.message}

                            <br/><br/>
                            source :
                            <br/>
                             <a className="text-blue-700" href={message.source} target="_blank">{message.source}</a>
                            
                        </div>
                    </div>
                )
            }
            else if(message.author=='Chatbot'){
                return(
                    <div className="m-1 self-start max-w-[80%] flex p-2 text-left">
                        <div className="resize-none rounded-md bg-blue-300 p-2 w-[100%]" disabled>{message.message}</div>
                    </div>
                )
            }
            else{
                return(
                    <div className="m-1 self-end max-w-[80%] p-2 text-left">
                        <div className="resize-none rounded-md bg-gray-300 p-2 w-[100%]" >{message.message}</div>
                    </div>
                )
            }
        })
        setElements(chatMessages)


    }

    useEffect(()=>{
        if(props.conversation!==undefined){
        setConversationId(props.conversation.pk)
        setDateCreated(props.conversation.created)
        setChatbot_name(props.conversation.chatbot_name)
        createChatMessages()
    }else{
        setElements()
    }
    },[props.conversation])



    return(
        <div className="bg-slate-200 w-[100%] max-h-[94vh] flex flex-col">

        <div className="w-[100%] bg-slate-200 flex flex-row">
            <p className="m-2">Conversation id : {conversationId} </p>
            <p className="m-2">Date created : {dateCreated} </p>
            <p className="m-2">Chatbot name : {chatbot_name} </p>
        

        </div>


        <div className="self-center bg-white w-full h-full flex flex-col overflow-y-scroll">
  
              {elements}   
            
        </div>
        
        </div>
    )
}

export default ConversationDisplay;