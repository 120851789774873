import { useState, createRef } from "react"
import {createProject, createProjectAdmin} from "./api/createProjectApi"
import { useNavigate } from "react-router-dom"
import { useAppState } from "../../hooks/AppState"
const CreateProjectPage = (props)=>{
    const [projectName, setProjectName] = useState()
    const [projectType, setProjectType] = useState('Company Website')
    const [website, setWebsite] = useState()
    const uploadFiles = createRef()
    const navigate = useNavigate()
    const appState = useAppState()

    const formValid = () =>{
        return true
    }

    async function formSubmit(e){
        e.preventDefault()

        
        if (formValid()){
            let id='None'
            if(appState.adminUser === false){
                console.log('not admn')
                let id = await createProject(projectName, projectType, website, uploadFiles)
            }else{
                let id = await createProjectAdmin(projectName, projectType, website, appState.adminSelectedUser.value)
            }
            appState.setProject({'name':projectName, 'value':id})
            navigate('/panel/create_chatbot')

        }

    }

    return(
        <div className="PageContainer">
        
            <div className=" mt-10 w-2/3 self-center bg-slate-300">
               <h1 className=" text-xl bg-slate-300">Create New Project</h1> 
            <form className="GridContainer m-4 bg-slate-200" onSubmit={formSubmit}>

                <label className="grid-item1" id="name" >Project Name</label>
                <input className="grid-item2" id="name" type="string" placeholder="My Chatbot" onChange={(e)=>setProjectName(e.target.value)}></input>

                <label className="grid-item1" id="type">Project Type</label>
                <select className="grid-item2" id="type" placeholder="Choose your website type.." onChange={(e)=>setProjectType(e.target.value)}>
                    <option value={'company-website'}>Company Website</option>
                    <option value={'store'}>Store</option>
                    <option value={'lawyer-bot'}>Lawyer Bot</option>
                </select>

                <label className="grid-item1" id="website">Website Link</label>
                <input className="grid-item2" id="website" type="string" placeholder="www.yourwebsite.com" onChange={(e)=>setWebsite(e.target.value)}></input>

                <label className="grid-item1" id="website">Additional Files</label>
                <input className="grid-item2" type='file' ref={uploadFiles}></input>

                <button className="Action-button bg-red-500" >Reset</button>
                <button className="Action-button bg-green-500"type='submit'>Create</button>
            </form>
            </div>
        </div>
    )
}

export default CreateProjectPage;