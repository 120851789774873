import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../components/other/Urls";
const api_url = Api_url()

async function getTasks(data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'task_functions/'+'?chatbot_id='+data,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

async function addTask(data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'task_functions/',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data': data,
        

        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}

async function updateTasks(data){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'task_functions/',{
        method:'PATCH',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
             'data': data,


        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json

            })}



export {getTasks, addTask, updateTasks};