import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../components/other/Urls";
import { GetRequest } from "../functions/requestTemplates";
import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
const api_url = Api_url()

const AuthContext = createContext()

const AuthProvider = ({children}) =>{
    const httpsEnabled = false
    const [user, setUser] = useState('')
    const [token, setToken] = useState(Cookies.get('token') || '')
    const [userType, setUserType] = useState('')
    const navigate = useNavigate()

    const loginAction = async (data)=>{
        try{
                const response = await fetch(api_url+'login',{
                    method:'post',
                    mode: 'cors',
                    credentials: 'same-origin',
                    headers: {
                    'X-CSRFToken':cookie.load('csrftoken'),
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })

                const res = await response.json();
                
                if(res.token){
                    setToken(res.token)
                    Cookies.set('token', 'Token '+String(res.token), {secure : httpsEnabled})

                    Authenticate()
                    navigate('/panel/')

                    return;
                }
                throw new Error(res.message);

                

        }catch(err){
            
        }
    };

    const logoutAction = () => {
        setUser(null);
        setToken("");
        Cookies.set('token', '', {secure : httpsEnabled});
        Cookies.set('user', '', {secure : httpsEnabled});
        navigate("/login");
      };

    const Authenticate = async()=>{
        const res = await GetRequest('auth/','')
        if (res.is_authenticated){
            setUser(res.username)
            setUserType(res.user_type)
        }else{
            logoutAction()
        }

    }


    useEffect(()=>{
        if(token !== '' && user === ''){

            Authenticate()
        }
    },[token])


    return(
    <AuthContext.Provider value={{token, user, userType, loginAction, logoutAction}}>
        {children}
    </AuthContext.Provider>
    )
}

export default AuthProvider;

export const useAuth = () =>{
    return useContext(AuthContext);
};