import { useEffect, useState } from "react";
import { PatchRequest } from "../../../functions/requestTemplates";
const CopyQuestions = (props) => {
    const [questionForm, setQuestionForm] = useState({collection_id:props.activeCollection.id, collection_to_id:null, operation:'copy'})

    const handleFormChange = (e)=>{
        setQuestionForm((prevState)=>{
            return {...prevState, [e.target.id]:e.target.value}
        })
    }


    const listOptions = ()=>{
        if (props.collectionList!==null){
            return props.collectionList.map((data)=>{
                if(String(data.pk) !== props.activeCollection.id){
                    return <option key={data.pk} value={data.pk}>{data.name}</option>
                }
                
                
            })
        }
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        const form = questionForm
        form.selected_ids = props.selected
        const data = await PatchRequest('question_answer_ext/', form)
        props.refresh()

    }
    return(
        <div>
            <h1>Copy questions</h1>
            <form onSubmit={(e)=>handleSubmit(e)}>

                <div className="mt-2">
                   <label>From Collection :</label><input className="rounded-md ml-5 text-center" disabled value={props.activeCollection.name}/>
                </div>

                <div className="mt-2">
                     <label>To Collection :</label><select id='collection_to_id' defaultValue={'none'} className="rounded-md ml-5 w-1/5 text-center" onChange={(e)=>handleFormChange(e)}>
                        <option value='none' disabled>Select...</option>
                        {listOptions()}
                     </select>
                </div>

                <input className="Action-button bg-blue-400 mt-5" type='submit' value={'Copy Questions'}></input>
            </form>
        </div>
    )
}

export default CopyQuestions;