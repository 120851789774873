import { useState, useEffect } from "react"
import { PatchRequest } from "../../../functions/requestTemplates"
import { useAppState } from "../../../hooks/AppState"
const CacheSettings = (props)=>{
    const [saveTo, setSaveTo] = useState()
    const [readFrom, setReadFrom] = useState()
    const [cacheThreshold, setCacheThreshold] = useState()
    const [loader, setLoader] = useState(false)
    const [edit, setEdit] = useState(false)
    const appState = useAppState()

    async function getCacheSettings(){
        console.log(appState.chatbot.data)
        setSaveTo(appState.chatbot.data['save_to_cache'])
        setReadFrom(appState.chatbot.data['read_from_cache'])
        setCacheThreshold(appState.chatbot.data['cache_threshold'])


    }

    async function saveCacheSettings(){
        const data={
            'chatbot_id':appState.chatbot.value,
            'save_to_cache':saveTo,
            'read_from_cache':readFrom,
            'cache_threshold':cacheThreshold,

        }
        const cache_data = await PatchRequest('chatbot_manage/', data)
        appState.getSession()
        
    }

    const toggleEdit = ()=>{
        const save_btn = document.getElementById('save_btn')
        const cancel_btn = document.getElementById('cancel_btn')
        if(edit){
            cancel_btn.classList.add('hidden')
            save_btn.classList.add('hidden')
            setEdit(false) 
        }else{
            cancel_btn.classList.remove('hidden')
            save_btn.classList.remove('hidden')
            setEdit(true)

        }

    }

    const formSubmit = () =>{
        saveCacheSettings()
    }

    useEffect(()=>{
        getCacheSettings()
    },[appState.chatbot])


    return(
        <div className="bg-slate-200" hidden={!props.show}>
            <h1 className="text-2xl m-5">Cache Settiings</h1>

       
        <button className="Action-button" onClick={()=>toggleEdit()}>Edit</button>
        <form className="GridContainer content-center justify-center" onSubmit={formSubmit}>

            <label className="grid-item1" id="name" >Save to Cache</label>
            <input className="grid-item2" id="name-input" type="checkbox" disabled={!edit} checked={saveTo} onChange={(e)=>setSaveTo(e.target.checked)}></input>
            
            <label className="grid-item1" id="name" >Read from Cache</label>
            <input className="grid-item2" id="name-input" type="checkbox" disabled={!edit} checked={readFrom} onChange={(e)=>setReadFrom(e.target.checked)}></input>

            <label className="grid-item1" id="cache_thresh" >Cache Similarity threshold</label>
            <input className="grid-item2" id="cache_thresh-input" type="number" disabled={!edit}  min={0.01} max={1.0} step={0.01} placeholder={0.90} value={cacheThreshold} onChange={(e)=>setCacheThreshold(e.target.value)}></input>
        </form> 

            <button className="Action-button bg-red-500 " hidden={!edit} id='cancel_btn' >Cancel</button>
            <button className="Action-button bg-green-500 " hidden={!edit} id='save_btn' type='submit' onClick={()=>formSubmit()}>Save</button>
            
        </div>
    )
}

export default CacheSettings;