import GetChatbots from './requestChatbots'
import {useState, useEffect} from 'react'

async function createChatbotDropdown(project){ 
    // creates a list of chatbots to be displayed in the dropdown menu
    if (project.value !== 'None' && project.value !== 'none'){
    let chatbot_list = []
    const chatbots = await GetChatbots(project.value)

    for(const i in chatbots){
        const array = chatbots[i]
        const element = 
                        <option className='Sidebar-option' key={i} name={array[0]} value={array[1]}>{array[0]}</option> 

                        
        chatbot_list.push(element)
        }
    return chatbot_list
    }else{
        return <option key={1} name={'noProject'} value={1} disabled>{'No project selected'}</option> 
    }
}

export {createChatbotDropdown}