import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppState } from "../../../hooks/AppState"
const CreateParser= (props)=>{
    const [rootUrl, setRootUrl] = useState()
    const [patternCrawl, setPatternCrawl] = useState()
    const [patternParent, setPatternParent] = useState()
    const [patternList, setPatternList] = useState()
    const [tagTemplates, setTagTemplates] = useState()

    const appState = useAppState()

    const formValid = () =>{
        return true
    }

    const handleReturn=()=>{
        props.setPage('core')
    }

    async function formSubmit (event){
        event.preventDefault()
        if (formValid()){
            const scrapper_config = {
                'root_url':rootUrl,
                'pattern_crawl':patternCrawl,
                'pattern_parent':patternParent,
                'pattern_list':patternList,
                'tag_templates':tagTemplates,
             
            }
            props.setSettings(scrapper_config)
            props.setSubmit(true)

        }

    }

    useEffect(()=>{

    },[])
    


    return(
        <div hidden={props.hide}>

        
        <form className="GridContainer m-2 bg-slate-200" onSubmit={formSubmit}>

        <div className="grid-item3 m-0 bg-slate-300">
            <h1 className="text-2xl">Website Scrapper Config</h1>
        </div>
   

            <label className="grid-item1" id="name" >Root URL</label>
            <input className="grid-item2" id="name-input" type="string"  defaultValue={rootUrl} onChange={(e)=>setRootUrl(e.target.value)}></input>

            <label className="grid-item1" id="urls" >scrap pattern crawl</label>
            <textarea className="grid-item2" id="urls-input" type="string" value={patternCrawl} onChange={(e)=>setPatternCrawl(e.target.value)}></textarea>

            <label className="grid-item1" id="pattern" >scrap pattern parent list</label>
            <textarea className="grid-item2" id="pattern-input" type="string" value={patternParent}  onChange={(e)=>setPatternParent(e.target.value)}></textarea>

            <label className="grid-item1" id="pattern" >scrap pattern list</label>
            <textarea className="grid-item2" id="pattern-input" type="string" value={patternList}   onChange={(e)=>setPatternList(e.target.value)}></textarea>

            <label className="grid-item1" id="pattern" >Tag Templates</label>
            <textarea className="grid-item2" id="pattern-input" type="string" value={tagTemplates}   onChange={(e)=>setTagTemplates(e.target.value)}></textarea>



        </form>
            <button className="Action-button  bg-red-500 "  id='cancel_btn_scrapper' type='return' onClick={(e)=>handleReturn(e)}>Return</button>
            <button className="Action-button bg-green-500" id='save_btn_scrapper' type="submit" onClick={formSubmit}>Next</button>
</div>
    )
}

export default CreateParser;