const AddChunks =(props)=>{
    return(
        <div hidden={props.hide}>
            <div className="bg-slate-300 ml-10 mt-4 mb-4">
                <h1>Add Chunks</h1>
            </div>

        </div>
    )
}

export default AddChunks;