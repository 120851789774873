import { useState, useEffect } from 'react'
import AdminGetProjects from '../../../functions/adminRequestProjects'
import { useAppState } from '../../../hooks/AppState'
const AdminProjectSelect = ()=>{
    const [projects, setProjects] = useState([])
    const [selected, setSelected]=useState('None')
    const appState = useAppState()

    const setActiveProject = (e) =>{
            setSelected(e.target.value)
            if (appState.project.value !== e.target.value){
                console.log(e)
                const activeProject = {'name' : e.target.name, 'value': e.target.value}
                appState.setProject(activeProject)
                }
    }

    async function CreateProjectDropdown(){
        let projects = []
        const project = await AdminGetProjects(appState.adminSelectedUser.value)
            for(const item in project){
            const array = project[item]
            const element =(
                            <option key={item} className='Sidebar-option' name={array[0]} value={array[1]}>{array[0]}</option>
                            )
            projects.push(element)       
        }
        setProjects(projects)
    }

    useEffect(()=>{
        if(appState.adminSelectedUser.value !== 'None' && appState.adminSelectedUser.value !== undefined){
            setSelected('None')
            appState.setProject({'name':'None', 'value': 'None'})
            appState.setChatbot({'name':'None', 'value': 'None'})
            CreateProjectDropdown()
        }
    },[appState.adminSelectedUser])

    

    return(
        <select className='Sidebar-select' value={selected} onChange={(e)=>setActiveProject(e)}>
            <option value={'None'} className='' disabled >Select Project</option>
            {projects} 
        </select>  
    )
}

export default AdminProjectSelect;