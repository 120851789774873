import "../../CSS/Login.css"
import { useState } from "react"
import { PostRequest } from "../../functions/requestTemplates"
import { useAppState } from "../../hooks/AppState"
const AdminManageUserPage = () =>{
    const appState = useAppState()

    const activateUser = async (e)=>{
        e.preventDefault()
        const response = await PostRequest('admin/activate_user/', {username: appState.adminSelectedUser.value})
        console.log(response)

    }

    const deactivateUser = async (e)=>{
        e.preventDefault()
        const response = await PostRequest('admin/deactivate_user/', {username: appState.adminSelectedUser.value})
        console.log(response)

    }






    return(
        <div className="PageContainer">
        <div className="flex w-full justify-center">
            <div className="Form-container">
                <h2>User : {appState.adminSelectedUser.value}</h2>
                <form className="flex flex-col">
                    <label >Active User : {appState.adminSelectedUser.is_active ? 'True' : 'False'}</label>
                    <label >Last Login : {appState.adminSelectedUser.last_login}</label>

                    {appState.adminSelectedUser.is_active 

                        ? <button className="Form-button" onClick={(e)=>deactivateUser(e)}>Deactivate User</button> 

                        : <button className="Form-button" onClick={(e)=>activateUser(e)}>Activate User</button>
                    }
                    


                    <button className="Form-button">Send Change Password</button>
                    
                </form>

            </div>
        </div>
    
    </div>)
}

export default AdminManageUserPage;