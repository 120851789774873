import { useState, useEffect } from "react"
import ParserWorkspace from "./pages/parserWorkspace"
import InfoSourcesWorkspace from "./pages/InfoSourcesPage"
import handleActiveButton from "../../functions/handleActiveButton"
import { useAppState } from "../../hooks/AppState"
const ChatbotDatabase = ()=>{
    const [loader, setLoader] = useState(false)
    const [workingPage, setWorkingPage] = useState('db-workspace')
    const appState = useAppState()
    const handlePage = (pageName)=>{
        setWorkingPage(pageName)
        handleActiveButton(pageName, workingPage)
    }


    useEffect(()=>{
    },[appState.chatbot.value])


    return(
        <div className="PageContainer">
            <div className='flex flex-row bg-slate-400'>
                <button className='Action-button-selected' value='db-workspace' id='db-workspace' onClick={(e)=>handlePage(e.target.value)}>Workspace</button>
                <button className='Action-button' value='db-infosources' id='db-infosources' onClick={(e)=>handlePage(e.target.value)}>Info Sources</button>
                
                </div>

            <div className=" self-center w-1/2">

            
      
            </div>
            <ParserWorkspace show={workingPage=='db-workspace'} handlePage={handlePage}/>
            
            <InfoSourcesWorkspace show={workingPage=='db-infosources'} handlePage={handlePage}/>

         
            </div>           
 
    )

}

export default ChatbotDatabase;