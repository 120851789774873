import { useState, createRef, useEffect } from "react"
import { Navigate, redirect, useNavigate } from "react-router-dom"
import { PatchRequest } from "../../../functions/requestTemplates"
import { useAppState } from "../../../hooks/AppState"
const PasrserConfig = (props)=>{
    const [simpleParserForm, setSimpleParserForm] = useState({base_url:'', start_url:'', crawl_patterns:'', scrap_patterns:'', max_links:'', tag_templates_basic:''})
    const [advancedParserForm, setAdvancedParserForm] = useState({root_url:'', pattern_crawl:'', pattern_parent:'', pattern_list:''})
    const [parserType, setParserType] = useState(0)
    const [tagTemplates, setTagTemplates] = useState()
    const [edit, setEdit] = useState(false)
    const appState = useAppState()


    const handleSimpleForm =(e)=>{
        setSimpleParserForm((prevState)=>{
            return {...prevState, [e.target.id]:e.target.value}
        })
    }

    const handleAdvancedForm =(e)=>{
        setAdvancedParserForm((prevState)=>{
            return {...prevState, [e.target.id]:e.target.value}
        })
    }

    const formValid = () =>{
        return true
    }


    const toggleEdit = ()=>{
        const save_btn = document.getElementById('save_btn_scrapper')
        const cancel_btn = document.getElementById('cancel_btn_scrapper')

        if(edit){
            cancel_btn.classList.add('hidden')
            save_btn.classList.add('hidden')
            setEdit(false) 
        }else{
            cancel_btn.classList.remove('hidden')
            save_btn.classList.remove('hidden')
            setEdit(true)
        }

    }
    async function formSubmit (event){
        event.preventDefault()
        if (formValid()){
            const scrapper_config = parserType == 1? simpleParserForm:advancedParserForm

            scrapper_config['chatbot_id'] = appState.chatbot.value
            scrapper_config['parser_type'] = parserType
            
            
            PatchRequest('chatbot_manage/',scrapper_config)
            toggleEdit()
            //appState.getSession()

        }

    }

    async function getData(){

        
        const dataAdvanced = {root_url:appState.chatbot.data['root_url'], 
                        pattern_crawl:appState.chatbot.data['pattern_crawl'], 
                        pattern_parent:appState.chatbot.data['pattern_parent'], 
                        pattern_list:appState.chatbot.data['pattern_list'],
                        tag_templates:appState.chatbot.data['tag_templates']
                        }
        const dataSimple = {base_url:appState.chatbot.data['base_url'],
                            start_url:appState.chatbot.data['start_url'],
                            crawl_patterns:appState.chatbot.data['crawl_patterns'],
                            scrap_patterns:appState.chatbot.data['scrap_patterns'],
                            max_links:appState.chatbot.data['max_links'],
                            tag_templates_basic:appState.chatbot.data['tag_templates_basic']
                        
                        }
        setSimpleParserForm(dataSimple)
        setAdvancedParserForm(dataAdvanced)
        setParserType(appState.chatbot.data['parser_type'])


    }

    useEffect(()=>{
        if(appState.chatbot.value !== "None" && appState.chatbot.value!=='none'){
        getData()
        }

    },[appState.chatbot])

    return(
        <div hidden={!props.show} className="bg-slate-200 self-center">

            <h1 className="text-2xl m-5">Website Scrapper Config</h1>

            <select className=" p-2 m-2 mb-5 ml-auto mr-auto rounded-md bg-blue-300" value={parserType} onChange={(e)=>setParserType(e.target.value)}>
                <option value={0}>Select Parser type</option>
                <option value={1}>Simple Parser</option>
                <option value={2}>Advanced Parser</option>
            </select>

            <button className="Action-button w-36 ml-5 self-center" disabled={appState.chatbot.value =="None"} onClick={()=>toggleEdit()}>Edit</button>


        { parserType == 2?
        
            <form className="GridContainer" onSubmit={formSubmit}>

                <label className="grid-item1" id="name" >Root URL</label>
                <input className="grid-item2" id="root_url" type="string"  value={advancedParserForm.root_url} disabled={!edit} onChange={(e)=>handleAdvancedForm(e)}></input>

                <label className="grid-item1" id="urls" >scrap pattern crawl</label>
                <textarea className="grid-item2" id="pattern_crawl" type="string" value={advancedParserForm.pattern_crawl} disabled={!edit} onChange={(e)=>handleAdvancedForm(e)}></textarea>

                <label className="grid-item1" id="pattern" >scrap pattern parent list</label>
                <textarea className="grid-item2" id="pattern_parent" type="string" value={advancedParserForm.pattern_parent} disabled={!edit}  onChange={(e)=>handleAdvancedForm(e)}></textarea>

                <label className="grid-item1" id="pattern" >scrap pattern list</label>
                <textarea className="grid-item2" id="pattern_list" type="string" value={advancedParserForm.pattern_list} disabled={!edit}  onChange={(e)=>handleAdvancedForm(e)}></textarea>
                                
                <label className="grid-item1" id="pattern" >Tag Templates</label>
                <textarea className="grid-item2" id="tag_templates" type="string" value={advancedParserForm.tag_templates} disabled={!edit}  onChange={(e)=>handleAdvancedForm(e)}></textarea>


            
            </form>
            
        : parserType == 1?
   

            <form className="GridContainer" onSubmit={formSubmit}>

                <label className="grid-item1" id="name" >Base URL</label>
                <input className="grid-item2" id="base_url" type="string"  value={simpleParserForm.base_url} disabled={!edit} onChange={(e)=>handleSimpleForm(e)}></input>

                <label className="grid-item1" id="name" >Start URL</label>
                <input className="grid-item2" id="start_url" type="string"  value={simpleParserForm.start_url} disabled={!edit} onChange={(e)=>handleSimpleForm(e)}></input>

                <label className="grid-item1" id="urls" >crawl patterns</label>
                <textarea className="grid-item2" id="crawl_patterns" type="string" value={simpleParserForm.crawl_patterns} disabled={!edit} onChange={(e)=>handleSimpleForm(e)}></textarea>

                <label className="grid-item1" id="pattern" >scrap patterns</label>
                <textarea className="grid-item2" id="scrap_patterns" type="string" value={simpleParserForm.scrap_patterns} disabled={!edit} onChange={(e)=>handleSimpleForm(e)}></textarea>

                <label className="grid-item1" id="pattern" >Tag Templates</label>
                <textarea className="grid-item2" id="tag_templates_basic" type="string" value={simpleParserForm.tag_templates_basic} disabled={!edit} onChange={(e)=>handleSimpleForm(e)}></textarea>
                
                <label className="grid-item1" id="pattern" >Max Links</label>
                <input className="grid-item2" id="max_links" type="number" value={simpleParserForm.max_links} disabled={!edit}  onChange={(e)=>handleSimpleForm(e)}></input>

            </form>  
            
        : <div></div>}

            <div className="grid-item3 m-2">
                <button className="Action-button  bg-red-500 hidden min-w-[120px] max-w-[240px] "  id='cancel_btn_scrapper' onClick={(e)=>getData(e)} >Cancel</button>
                <button className="Action-button bg-green-500 hidden min-w-[120px] max-w-[240px]" id='save_btn_scrapper' onClick={(e)=>formSubmit(e)}>Save</button>

            </div>

        

            </div>

    )
}

export default PasrserConfig;