import GetChatbots from "../../../../functions/requestChatbots"
import { useState, useEffect } from "react"
import { CopySettings } from "../../Api"
import { useAppState } from "../../../../hooks/AppState"
const CopyToExistingComponent = (props)=>{
    const[chatbotFrom, setChatbotFrom] =useState()
    const[chatbotTo, setChatbotTo]=useState()
    const[copyConfig, setCopyConfig]=useState()
    const[copyVdb, setCopyVdb]=useState()
    const[copyCache, setCopyCache]=useState()
    const[copyParser, setCopyParser]=useState()
    const[copyTasks, setCopyTasks]=useState()
    const appState = useAppState()


    async function handleCopy(){
        const config = {
            'chatbot_from':chatbotFrom,
            'chatbot_to':chatbotTo,
            'copyConfig' :copyConfig,
            'copyCache':copyCache,
            'copyParser':copyParser,
        }
        const res = await CopySettings(config)
        props.setShow(false)

    
    }


    return(
        <div hidden={props.hide}>

        <h1>Copy configuration between chatbots</h1>
            <div  className="flex flex-row">
                


                    <div className="flex flex-col w-1/2">
                    <label>Copy from</label>
                        <select id='from' onChange={(e)=>setChatbotFrom(e.target.value)}>
                            <option>Select chatbot to copy from</option>
                            {appState.chatbotDropdown}
                        </select>
                    </div>

                    <div className="flex flex-col w-1/2">
                    <label>Copy to</label>
                        <select onChange={(e)=>setChatbotTo(e.target.value)}>
                            <option>Select chatbot to copy from</option>
                            {appState.chatbotDropdown}
                        </select>
                    </div>
            </div>
            
            <div className="m-4">
                <label>Copy Configuration</label>
                <input className='m-2'type='checkbox' onChange={(e)=>setCopyConfig(e.target.checked)}></input>
            </div>



            <div className="m-4">
                <label>Copy cache</label>
                <input className='m-2'type='checkbox' onChange={(e)=>setCopyCache(e.target.checked)}></input>
            </div>


            <div className="m-4">
                <label>Copy parser</label>
                <input className='m-2'type='checkbox' onChange={(e)=>setCopyParser(e.target.checked)}></input>
            </div>


            <div>
                <button className="Action-button bg-red-400" onClick={()=>props.setShow(false)}>Cancel</button>
                <button className="Action-button bg-green-400" onClick={()=>handleCopy()}>Create Copy</button>

            </div>



        </div> 
    )
}

export default CopyToExistingComponent;