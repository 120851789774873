import { useEffect, useState } from "react";
import { PatchRequest } from "../../../functions/requestTemplates";

const QuestionComponent = (props) =>{
    const [edit, setEdit] = useState(false)
    const [questionForm, setQuestionForm] = useState({question:props.question, answer:props.answer, action:props.action, collection_id:props.qa_collection, id:props.id})
    const [preEditForm, setPreEditForm] = useState({question:props.question, answer:props.answer, action:props.action, collection_id:props.qa_collection, id:props.id})
    const handleUpdateForm = (e)=>{
        setQuestionForm((prevState)=>{
            return {...prevState, [e.target.id]:e.target.value}
        })
    }

    const handleSave = ()=>{
        setEdit(false)
        PatchRequest('question_answer/', questionForm)
    }

    const handleCancel = ()=>{
        setEdit(false)
        setQuestionForm(preEditForm)
    }

    const handleGenerateAnswer = async(e)=>{
        e.preventDefault()
        const form = questionForm
        form.operation = 'generate'
        const data = await PatchRequest('question_answer_ext/', form)
        props.refresh()
    }

    const handleSelect = (e)=>{
        console.log(e.target)
        if (e.target.checked){

            props.setSelected((prevState)=>{return [...prevState, e.target.value]})

            
        }else{

            props.setSelected((prevState)=>{return prevState.filter((id)=>{return id!=e.target.value})})
            
        }
        
    }

    const handleSelectAll = ()=>{
        const checkbox= document.getElementById('checkbox'+props.id)
        checkbox.checked = props.selectAll
        handleSelect({target:checkbox})
    }

    useEffect(()=>{handleSelectAll()},[props.selectAll])
    
    useEffect(()=>{
        setQuestionForm({question:props.question, answer:props.answer, action:props.action, collection_id:props.qa_collection, id:props.id})  
    },[props.collection])

    return (
        <div className="grid m-3 grid-cols-4 space grid-rows-1 flex-row rounded-sm p-1 bg-slate-400">

            <div className="p-1 mr-1 row-span-1 col-span-1">
                <textarea  className="w-full h-full rounded-md resize-none scroll-y-auto [scrollbar-width:thin]"
                           id='question' value={questionForm.question} disabled={!edit} onChange={(e)=>handleUpdateForm(e)}></textarea>
            </div>

            <div className="p-1 ml-1 row-span-1 col-span-1 overflow-hidden rounded-md">
                <textarea  className="w-full h-full rounded-md resize-none scroll-y-auto [scrollbar-width:thin]" 
                           id='answer' value={questionForm.answer} disabled={!edit} onChange={(e)=>handleUpdateForm(e)}></textarea>
            </div>

            <div className="bg-white rounded-md p-1 ml-3 row-span-1 col-span-1 mt-1 mb-1">
                <h1>
                    {questionForm.action}
                </h1>
            </div>

            <div className=" col-span-1 row-span-1 flex flex-col w-4/5 pl-[21%] h-full">

                <button className="Action-button h-7 bg-blue-400">Generate answer</button>

                { edit ? 
                    <div>   
                        <button className="Action-button h-7 w-[48%] bg-green-400" onClick={()=>handleSave()}>Save</button>
                        <button className="Action-button h-7 w-[48%] bg-red-400" onClick={()=>handleCancel()}>Cancel</button>
                    </div>        
                : 
                    <div className="flex flex-col">
                        <button className="Action-button h-7 w-full bg-green-400" onClick={()=>setEdit(true)}>Edit</button>
                    </div>
                }

                <div className="flex self-center">
                    <h1 className="mr-2">Select</h1><input id={'checkbox'+props.id} className="w-4" type="checkbox" value={props.id} onClick={(e)=>handleSelect(e)}></input>
                </div>

            </div>

        </div>
    )
}

export default QuestionComponent;