import { useState, useEffect } from "react"
import { PostRequest } from "../../../functions/requestTemplates"
const CreateCollection = (props) => {
    const [createForm, setCreateForm] = useState({name:'', active:'', collect:''})
    const [errorMessage, setErrorMessage] = useState('')
    const handleUpdateForm = (e)=>{
        let value = null
        if(e.target.value==='true'){
            value=true
        }
        else if(e.target.value==='false'){
            value=false
        }
        else{
            value=e.target.value
        }
        setCreateForm((prevState)=>{
            return {...prevState, [e.target.id]:value}
        })
    }

    const formSubmit = async (e)=>{
        setErrorMessage('')
        e.preventDefault()
        if (createForm.name!=='' && createForm.active!=='' && createForm.collect!==''){
            createForm.chatbot_id = props.chatbot_id
            await PostRequest('question_collection/', createForm)
            props.handleGetCollections()
        }else{
            setErrorMessage('Please fill out all fields')
        }}
    return (


        
        <div className="flex flex-col w-full max-h-[94vh] p-10 bg-slate-300">
                <h1 className="text-red-600">{errorMessage}</h1>
                <h1>Create Collection</h1>
                <form className="mt-5 self-center" onSubmit={(e)=>formSubmit(e)}>

                    
                
                <div className="flex flex-row mt-2">
                <h2>Name:</h2>
                <input className="w-48 border rounded-md ml-2" id='name' onChange={(e)=>handleUpdateForm(e)} type=""></input>
                </div>

                <div className="flex flex-row mt-2">
                <h2>Active:</h2>
                <select className="w-48 border rounded-md ml-3" id='active' defaultValue={0} onChange={(e)=>handleUpdateForm(e)} >
                    <option disabled value={0}>Select...</option>
                    <option value={true}>Enabled</option>
                    <option value={false}>Disabled</option>
                </select>
                </div>

                <div className="flex flex-row mt-2">

                <h2>Collect:</h2>
                <select className="w-48 border rounded-md ml-2" id='collect' defaultValue={0} onChange={(e)=>handleUpdateForm(e)} >
                <option disabled value={0}>Select...</option>
                    <option value={true}>Enabled</option>
                    <option value={false}>Disabled</option>
                </select>
                </div>

                <input className="Action-button bg-blue-400 mt-8" type="submit" value={'Create new Collection'}></input>
                </form>


        </div>

    )
}

export default CreateCollection;