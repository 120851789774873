import HeaderRow from "./HeaderRow";
import DataRow from "./DataRow";


const CacheTable = (data) =>{
    const HeadData = data.Headers
    const rows = data.Data


    return(
        <div id='ct' className="CacheTable-container">
        <table className="CacheTable">
            <thead>
                <HeaderRow HeadData={HeadData}/>
            </thead>
            <tbody>
            <tr>
            </tr>                
            </tbody>
                <DataRow RowsData={rows} RowSetter = {data.RowState}/>
        </table>
        </div>
    )

}

export default CacheTable;