const handleActiveButton = (id, prevId)=>{
    const oldElement = document.getElementById(prevId)
    const element = document.getElementById(id)

    oldElement.classList.remove('Action-button-selected')
    oldElement.classList.add('Action-button')

    element.classList.remove('Action-button')
    element.classList.add('Action-button-selected')
}

export default handleActiveButton;