import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../components/other/Urls";
const api_url = Api_url()
async function GetChatbots(project){
    const token = String(Cookies.get('token'))

    return (await fetch(api_url+'cms_get_chatbots/?project_id='+project,{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Authorization': token,
        },})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.chatbots

            }))}

export default GetChatbots;