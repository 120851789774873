import { useState, useEffect } from "react";
import { updateChatbotData } from "../chatbot_config/api/chatbotDataApi";
import { useAppState } from "../../hooks/AppState";
const FunctionsPage = () => {
  const [showLinkOptions, setShowLinkOptions] = useState(false);
  const [enableCustomLink, setEnableCustomLink] = useState(true);
  const [linkOptionsForm, setLinkOptionsForm] = useState({enabled:'', linkRequire:'', maxLinks:1, customLink:false, customLinkText:''})
  const [sourceOptionsForm, setSourceOptionsForm] = useState({enabled:''})

  const appState = useAppState()

  const getFunctionData =()=>{
    const functionData = appState.chatbot.data['chatbot_functions']

    if (functionData['LinkFunction']){
      setLinkOptionsForm(functionData['LinkFunction'])

    }else{

      setLinkOptionsForm({enabled:'', linkRequire:'', maxLinks:1, customLink:false, customLinkText:''})
    }
    if(functionData['SourceFunction']){

      setSourceOptionsForm(functionData['SourceFunction'])
    }else{

      setSourceOptionsForm({enabled:''})
    }

  }

  const updateChatbotFunctions = async (e)=>{
    const functionConfig = {
      'chatbot_id':appState.chatbot.value,
      'chatbot_functions':{'LinkFunction' : linkOptionsForm, 'SourceFunction':sourceOptionsForm}
    }
    updateChatbotData(functionConfig)
  }

  const handleUpdateForm = (e)=>{ 


    setLinkOptionsForm((prevState)=>{
        return {...prevState, [e.target.id]:e.target.value}
    })


}
  const toggleOptions =(e)=>{
    let linkOptions = document.getElementById('linkOptions')
    if (showLinkOptions){
      linkOptions.classList.add('hidden')
    }else{
      linkOptions.classList.remove('hidden')
    }
    setShowLinkOptions(!showLinkOptions)
  }

  const toggleEnabled = (e)=>{
    setLinkOptionsForm((prevState)=>{
      return {...prevState, enabled:e.target.checked}
    })
  }

  const toggleEnabledSource = (e)=>{
    setSourceOptionsForm((prevState)=>{
      return {...prevState, enabled:e.target.checked}
    })
  }

  const toggleCustomLink = (e)=>{
    setEnableCustomLink(!e.target.checked)
    setLinkOptionsForm((prevState)=>{
      return {...prevState, customLink:e.target.checked}
    })
  }

  useEffect(()=>{
    if(appState.chatbot.data !== undefined){

      getFunctionData()
    }
    
  },[appState.chatbot])
  
  return (
    <div className="PageContainer">


      <div className="flex flex-col  text-lg">
        <div className="self-center p-10 m-5 bg-blue-200 w-[80%]">
        <div className="grid w-[100%] self-center bg-blue-200 ">
          <label className="grid-item1 bg-slate-100">Function Type</label>
          <label className="grid-item2 bg-slate-100"> Function Enabled</label>
          <label className="grid-item4 bg-slate-100"> Function Options</label>

          <label className="grid-item1 ">Add Links to text</label> 
          <input className="grid-item2" type="checkbox" id='enabled' checked={linkOptionsForm.enabled} onChange={(e)=>toggleEnabled(e)}></input>
          <button className=" Action-button grd-item4 p-0 w-[50%] ml-auto mr-auto" onClick={(e)=>toggleOptions(e)}>Show Options</button>

          <form className="grid-item3">

          <div id='linkOptions' className="grid bg-slate-200 hidden">
            <label className="grid-item3">Options</label>
            
            
                <label className="grid-item1">Add Link if chatbot mentions:</label>
                <input className='grid-item2 rounded-md' placeholder={'Product, Service'} id='linkRequire' value={linkOptionsForm.linkRequire}  type="text" onChange={(e)=>handleUpdateForm(e)}></input>
            


                <label className="grid-item1">Max number of links:</label>
                <input className='grid-item2 rounded-md' type="number" id='maxLinks' defaultValue={1} min={1} max={5} step={1} value={linkOptionsForm.maxLinks} onChange={(e)=>handleUpdateForm(e)}></input>


                <label className="grid-item1">Use Custom link text:</label>
                <input className='grid-item2 rounded-md' type="checkbox" id='customLink' checked={linkOptionsForm.customLink} onChange={(e)=>toggleCustomLink(e)}></input>
   

                <label className="grid-item1">Custom Link Text:</label>
                <input className='grid-item2 rounded-md' disabled={enableCustomLink} id='customLinkText' type="text" value={linkOptionsForm.customLinkText} onChange={(e)=>handleUpdateForm(e)}></input>
    
          </div>

          </form>

          <label className="grid-item1 ">Add All Sources</label> 
          <input className="grid-item2" type="checkbox" id='enabled' checked={sourceOptionsForm.enabled} onChange={(e)=>toggleEnabledSource(e)}></input>

          
          
        </div>
        <button className="Action-button bg-green-400 mt-10" onClick={(e)=>updateChatbotFunctions(e)}>Save Changes</button>
      </div>

      </div>

    </div>
  );
};

export default FunctionsPage;