import cookie from "react-cookies";
import Cookies from "js-cookie";
import Api_url from "../../../components/other/Urls";
import { PostRequest } from "../../../functions/requestTemplates";
const api_url = Api_url()

async function createCache(data){
    return await PostRequest('cache_manage/', data)

}

async function createChatbot(core_config){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'chatbot_manage/',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'chatbot_config' : core_config


        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.chatbot_id

            })}

async function createScrapper(scrap_config, chatbot_id){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'parser_manage/',{
        method:'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },
        body: JSON.stringify({
            'data':scrap_config



        })})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.Response

            })}



async function getEmbedders(){
    const token = String(Cookies.get('token'))

    return await fetch(api_url+'cms_get_embedders',{
        method:'get',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
        'X-CSRFToken':cookie.load('csrftoken'),
        'Content-Type': 'application/json',
        'Authorization': token,
        },})
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            return json.embedders

            })}

export {getEmbedders, createChatbot, createScrapper, createCache};