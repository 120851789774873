const HeaderRow = (HeadData) =>{
    let data = HeadData.HeadData
    

    let elements = []
    
    const createHeader = (data)=>{
        elements.push(<th key='1'>Select</th>)
        const map = data.map((headStr, i)=>{
            let key = (''+0+'.'+i)
            elements.push(<th key={key}>{headStr}</th>)     
        })
        

    }
    if(data !== undefined){

        createHeader(data)
    }
    

    return(

            <tr>
                {elements}
            </tr>
 
    )
}

export default HeaderRow