import { useState, useEffect } from "react";
import { getConversations, deleteConversation } from "../converationApi";
import { TrashIcon, PaperPlaneIcon} from '@radix-ui/react-icons'
import { Input } from "@/src/components/ui/input";
import './conversationList.css'
const ConversationList = (props)=>{
    const [conversationsList, setConversationsList] = useState(null)
    const [elements, setElements] = useState()
    const [searchId, setSearchId] = useState('')

    const handleConversationChange = (i)=>{
        props.setConversation(conversationsList.data[i])

    }

    const handleConversationDelete =(id)=>{
        deleteConversation(id)
        const element = document.getElementById(id)
        element.remove()
    }

    const handleSearchChange = (value) =>{
        setSearchId(value)
    }

    const listElements = ()=>{
        const elementsMap = conversationsList.data.map((element, index)=>{
        if(element.messages.length > 1){
            let text = ''

            for(const i in element.messages){
                text = text + ' ' + element.messages[i].message
            }

            if(text.toLowerCase().includes(searchId.toLowerCase()) || searchId==null){
                const first_user_msg = element.messages[1].message

                return(
                <div className=" self-center flex flex-row m-2 p-2 bg-slate-100 rounded-sm border border-black" id={element.pk} >
                    <div className="truncated">{first_user_msg}</div>
                    <button className="Action-button bg-blue-400 ml-auto h-10" value={index} onClick={(e)=>handleConversationChange(e.target.value)}>
                        <PaperPlaneIcon pointerEvents={"none"}/>
                    </button>

                    <button className="Action-button bg-red-400 h-10" value={index} onClick={(e)=>handleConversationDelete(element.pk)} >
                        <TrashIcon/>
                    </button>
                </div>
            )}
        }
        }
        )

        setElements(elementsMap)
    }

    const fetchConv = async ()=>{
        const conversations = await getConversations(props.chatbot.value)
        setConversationsList(conversations)
    }

    useEffect(()=>{
        fetchConv()
    },[props.chatbot])

    useEffect(()=>{
        if(conversationsList!=null){
            listElements()
        }
    },[conversationsList, searchId])

    return(
        <div className="w-[35%] bg-slate-200 flex flex-col">
            <div className="flex p-2">
                <Input
            type="text"
            name='message'
            placeholder="Search..."
            onChange={(e)=>handleSearchChange(e.target.value)}
            
            className="p-1 max-w-full border-blue-200 mr-2"
        />
            </div>
                <div className="h-full overflow-y-scroll [scrollbar-width:thin]">
                    <div className=" max-h-[89vh]">
                        {elements}
                    </div>

                </div>

            
        </div>
    )
}

export default ConversationList;