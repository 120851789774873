import InfoLinks from "../componenets/workspaceInfoSources";
import EmbeddingComponenet from "../componenets/workspaceEmbedding";
import { useAppState } from "../../../hooks/AppState";
const ParserWorkspace = (props)=>{
    const appState = useAppState()

    return (
        <div hidden={!props.show}>

        <div className="bg-slate-400 flex m-4 min-h-[700px] max-h-[700px]">


        <InfoLinks chatbot ={appState.chatbot}/>
                
        <EmbeddingComponenet/>
            
        </div>



        </div>
    )
}

export default ParserWorkspace;