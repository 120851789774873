import { useState, useEffect } from "react";
import InfoLinks from "../componenets/workspaceInfoSources";
import { useAppState } from "../../../hooks/AppState";
const InfoSourcesWorkspace = (props)=>{
    const [chatbotLeft, setChatbotLeft] = useState()
    const [chatbotRight, setChatbotRight] = useState()
    const appState = useAppState()
    return (
        <div hidden={!props.show}>

        <div className="flex m-4 flex-row bg-slate-400 max-h-[800px]">

        <div className="w-1/2">    

            <select className="p-1 rounded-md mt-2 bg-sky-400" onChange={(e)=>setChatbotLeft({'value':e.target.value})}>
                <option >Select a chatbot</option>
                {appState.chatbotDropdown}
            </select>

            <div className="bg-slate-400 flex min-h-[700px] max-h-[700px]">

                <InfoLinks chatbot={chatbotLeft}/>

            </div>

        </div>

        <div className="w-1/2">  

            <select className="p-1 mt-2 rounded-md bg-sky-400" onChange={(e)=>setChatbotRight({'value':e.target.value})}>
                <option >Select a chatbot</option>
                {appState.chatbotDropdown}
            </select>

            <div className="bg-slate-400 flex min-h-[700px] max-h-[700px]">

                <InfoLinks chatbot={chatbotRight}/>

            </div>

        </div>

        
                
            
        </div>



        </div>
    )
}

export default InfoSourcesWorkspace;