import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getEmbedders } from "../api/createChatbotApi"
import { useAppState } from "../../../hooks/AppState"
const CreateCore= (props)=>{
    const [chatbotName, setChatbotName] = useState('')
    const [embedder, setEmbedder] = useState(0)
    const [welcomeMessage, setWelcomeMsage] = useState('')
    const [unknownMessage, setUnknownMesage] = useState('')
    const [prompt, setPrompt] = useState('')
    const [chatbotType, setChatbotType] = useState(0)
    const [cacheThreshold, setCacheThreshold] = useState()
    const [dataThreshold, setDataThreshold] = useState()
    const [loader, setLoader] = useState(false)
    const [embedders, setEmbedders] = useState()
    const navigate = useNavigate()
    const appState = useAppState()
    const formValid = () =>{
        return true
    }
    
    const cancel = ()=>{
        navigate('/panel/project_settings')
    }

    async function showEmbedders(){       
        let embedder_list = []
        const embedder = await getEmbedders(setLoader)

        for(const i in embedder){
            const array = embedder[i]
            const element = <option key={i} name={array[0]} value={array[1]}>{array[0]}</option> 
            embedder_list.push(element)
                 
        }
        setEmbedders(embedder_list)}
    

    async function formSubmit (event){
        event.preventDefault()
        if (formValid()){
            const chatbot_config = {
                'chatbot_name': chatbotName,
                'project_id':appState.project.value,
                'embedder_id': embedder,
                'welcome_message': welcomeMessage,
                'unknown_message': unknownMessage,
                'data_threshold':dataThreshold,
                'prompt': prompt,
                'chatbot_type': chatbotType
            }
            props.setSettings(chatbot_config)
            props.setPage('parser')
        }
    }




    useEffect(()=>{
        showEmbedders()

    },[])


    
    return(
            <div hidden={props.hide}>
            <form className="GridContainer m-2 bg-slate-200" onSubmit={formSubmit}>

            <div className="grid-item3 m-0 bg-slate-300">
                <h1 className="text-2xl">Chatbot Core Config</h1>
            </div>

                <label className="grid-item1" id="name" >Chatbot Name</label>
                <input className="grid-item2" id="name" type="string" placeholder="My Chatbot" value={chatbotName} onChange={(e)=>setChatbotName(e.target.value)}></input>
                
                <label className="grid-item1" id="name" >Chatbot Function</label>
                <select className="grid-item2" id="embedder-select"  value={chatbotType} onChange={(e)=>setChatbotType(e.target.value)}>
                    <option value={0} disabled>Select Type</option>
                    <option value={'Website'}>Website Assistant</option>
                    <option value={'Shop'}>Shop Assistant</option>
                    <option value={'Lawyer'}>Lawyer Assistant</option>
                </select>

                <label className="grid-item1" id="type">Embedder</label>
                <select className="grid-item2" id="type" value={embedder} onChange={(e)=>setEmbedder(e.target.value)}>
                    <option value={0} disabled>Select embedder</option>
                    {props.embedders}
                </select>

                <label className="grid-item1" id="name" >Prompt</label>
                <textarea className="grid-item2" id="name" type="string" placeholder="" value={prompt} onChange={(e)=>setPrompt(e.target.value)}></textarea>


                <label className="grid-item1" id="name" >Welcome message</label>
                <textarea className="grid-item2" id="name" type="string" value={welcomeMessage} placeholder="Welcome to our site, ask me anything about our company" onChange={(e)=>setWelcomeMsage(e.target.value)}></textarea>

                <label className="grid-item1" id="name" >Unknown message</label>
                <textarea className="grid-item2" id="name" type="string" value={unknownMessage}placeholder="Im sorry, i don't have any knowledge on that topic" onChange={(e)=>setUnknownMesage(e.target.value)}></textarea>

                <label className="grid-item1" id="name" >Data Similarity threshold</label>
                <input className="grid-item2" id="name" type="number" value={dataThreshold} min={0.01} max={1.0} step={0.01} placeholder={0.90} onChange={(e)=>setDataThreshold(e.target.value)}></input>



            </form>
                <button className="Action-button bg-red-500" onClick={()=>{cancel()}}>Cancel</button>
                <button className="Action-button bg-green-500" onClick={(e)=>{formSubmit(e)}}type='submit'>Next</button>
            </div>

    )
}

export default CreateCore;