import {DemoTable} from "../components/DataTable/table";
import ChatWindow from "../components/DataChat/ChatWindow";
import { PostRequest } from "@/src/functions/requestTemplates";
import { useEffect, useState } from "react";
import { useAppState } from "@/src/hooks/AppState";
import "../../../index.css"


const DatabasePage = (props) => {
    const [tableData, setTableData] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedIds, setSelectedIds] = useState([])
    const appState = useAppState()

    const getSelectedRowsId = ()=>{
        const ids = []
        for(const i in selectedRows){
            ids.push(tableData[i].id)
        }
        setSelectedIds(ids)
    }

    const get_chunk_data = async ()=>{
        const chatbot_id = appState.chatbot.value
        const chunk_data = await PostRequest('table_chunks/',{'chatbot_id':chatbot_id,'search_query':' ','max_results':500, 'partition':'UK_GOV_LAW_EMPLOYMENT'})
        setTableData(chunk_data.data)
        console.log(chunk_data.data)
    }

    const handleTableSearch= async (query)=>{
        const chatbot_id = appState.chatbot.value
        const chunk_data = await PostRequest('table_search/',{'chatbot_id':chatbot_id,'search_query':query,'max_results':5, 'partition':'UK_GOV_LAW_EMPLOYMENT'})
        setTableData(chunk_data.data)
        console.log(chunk_data.data)

    }

    const handleShowChat =(mode)=>{
        const chatWindow = document.getElementById('chat')
        if(mode=='Show AI Chat'){
            console.log('adding width')
            
            chatWindow.classList.add('w-3/4')
            chatWindow.classList.remove('w-0')
        }else{
            chatWindow.classList.remove('w-3/4')
            chatWindow.classList.add('w-0')
        }

    }
    useEffect(()=>{
        get_chunk_data()
    },[appState.chatbot.value])

    useEffect(()=>{
        getSelectedRowsId()
    },[selectedRows])
    return (

            <div className="flex flex-row h-full">
                <div className="h-full w-full overflow-y-auto overflow-x-auto [scrollbar-width:thin] ">
 
                    <DemoTable handleShowChat={handleShowChat} tableData={tableData} handleTableSearch={handleTableSearch} setSelectedRows={setSelectedRows}/>
                </div>
                

                <div id='chat' className="w-0 border-l">
                    <ChatWindow selectedIds={selectedIds} />

                </div>

            </div>

    );
}

export default DatabasePage;