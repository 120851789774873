const ChatMessageBot = (props) =>{
    return(
        <div className=" w-fit max-w-[95%] text-sm rounded-sm bg-blue-300 my-1 p-2 text-left">
            {props.message}
            <br/>
            { props.link !== null ? <>Source <br/><br/> <a className=" text-blue-700 underline" target="_blank" href={props.link}>{props.link}</a></> : <></>}
        </div>
    )
}

const ChatMessageUser = (props) =>{
    return(
        <div className="max-w-[95%] text-sm rounded-sm bg-slate-200 my-1 ml-auto p-2 text-left">
            {props.message}

        </div>
    )
}


export {ChatMessageBot, ChatMessageUser};